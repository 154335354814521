Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";
exports.landingPageDataApiEndpoint="bx_block_landing_page/admin_landing_pages/show";
exports.landingPageDataApiEndpointApiType="GET";
exports.serviceDataApiEndpoint="bx_block_landing_page/admin_landing_pages/get_services";
exports.serviceApiEndpointApiType="GET";
exports.logoDataApiEndpoint="bx_block_landing_page/logo_lists";
exports.logoApiEndpointApiType="GET";
exports.faqDataApiEndpoint="bx_block_landing_page/admin_landing_pages/get_faq";
exports.faqApiEndpointApiType="GET";
exports.leaderShipDataApiEndpoint="bx_block_landing_page/admin_landing_pages/get_leadership";
exports.leaderShipApiEndpointApiType="GET";
exports.patnerDataApiEndpoint="bx_block_landing_page/admin_landing_pages/get_all_partner";
exports.patnerApiEndpointApiType="GET";
exports.contactpiContentendpoint = "bx_block_landing_page/admin_landing_pages/contact_us";
exports.contactApiMethodType = "GET";
exports.btnExampleTitle = "CLICK ME";
exports.getProfileApiEndPoint = "bx_block_profile/profiles/get_profile";
exports.getProfileApiMethodType = "GET";
// Customizable Area End