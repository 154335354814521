import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

export const configJSON = require("./config");

// Customizable Area Start
import React from "react";
import { getStorageData } from "../../../framework/src/Utilities";
import moment from "moment";
import {
  format,
  addDays,
  getDay,
  subMonths,
  addMonths,
  parseISO,
} from "date-fns";

export interface ApiCallInterface {
  contentType?: string;
  method?: string;
  endPoint?: string;
  body?: object;
}

export interface ValidResponseType {
  message: object;
  data: object;
  errors: string;
}

export interface DataofService {
  id: string;
  type: string;
  attributes: {
    id: number;
    service_icon?: {
      url: string;
    };
    service_name: string;
    service_description: string;
    is_selected: boolean;
  };
}

interface RecipientErrors {
  nameErr: boolean;
  emailErr: boolean;
  emailInvalidErr: boolean;
}

interface Document {
  id: number;
  isfileMax: boolean;
  isInvalidSize: boolean;
  document?: File | null;
  base64: string;
  doc_size: number;
  ellapsed: boolean;
  recipients_attributes: Recipient[];
}

interface Recipient {
  name: string;
  email: string;
  is_signatory: boolean;
}

type PriorityType =
  | "Standard"
  | "Priority"
  | "Super Priority"
  | "Not Available";

interface Priorities {
  [key: string]: PriorityType;
}

export interface NotarisationMethod {
  id: number;
  notarisation_method: string;
  created_at: string;
  updated_at: string;
}

export interface Juridiction {
  id: number;
  jurisdiction: string;
  created_at: string;
  updated_at: string;
}

interface NotaryRequest {
  id: string;
  type: string;
  attributes: Attributes;
  relationships: Relationships;
}

interface Attributes {
  status: string;
  notary_id: number | null;
  notarisation_method_id: number;
  notary_service_name: string;
  priority: string;
  notary_service_type: number;
  jurisdiction_id: number;
  date: string;
  notes: string;
  notarised_document: number;
  timing_slot: string;
  file_documents: FileDocumentsEntity[];
  juridiction: Juridiction;
  notarisation_method: NotarisationMethod;
  quote_statuses_id: number;
}

interface FileDocumentsEntity {
  doc_id: number;
  doc_type: string;
  doc_size: number;
  doc_base_64: string;
  doc_name: string;
  doc_file_url: string;
  signatory_count: number;
  recipients?: RecipientsEntity[] | null;
}

interface RecipientsEntity {
  id: number;
  created_at: string;
  updated_at: string;
  file_document_id: number;
  name: string;
  email: string;
  is_signatory: boolean;
  signed: boolean;
  is_notary: boolean;
}

interface Relationships {
  jurisdiction: JurisdictionOrNotaryOrNotarisationMethodOrAccount;
  notary: JurisdictionOrNotaryOrNotarisationMethodOrAccount;
  notarisation_method: JurisdictionOrNotaryOrNotarisationMethodOrAccount;
  account: JurisdictionOrNotaryOrNotarisationMethodOrAccount;
}
interface JurisdictionOrNotaryOrNotarisationMethodOrAccount {
  data: Data | null;
}
interface Data {
  id: string;
  type: string;
}
interface PriorityMethod {
  priority: number;
  date: any;
}
type PriorityMethodArray = PriorityMethod[];
interface DateRange {
  firstDay: string;
  lastDay: string;
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  isOpen: boolean;
  closeModal: () => void;
  allRequestAPI?: () => void;
  serviceData: Array<DataofService>;
  cancelReqModal: boolean;
  yesButtonClick: () => void;
  noButtonClick: () => void;
  isNewRequestOrEditRequestOrInviteClient: string;
  editRequest: NotaryRequest | undefined;
  setLoader: (value: boolean) => void;
  setModal: (value: boolean) => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isSignatoryAvailable: boolean[];
  onStep: number;
  selectedService: string;
  isSelectService: boolean;
  selectedMethod: string;
  isSelectedMethod: boolean;
  selectedDate: Date | null;
  tempSelectedDate: Date | null;
  tempSelectedSession: string;
  selectedSession: string;
  isCalendarOpen: boolean;
  currentMonth: Date;
  priorities: { [key: string]: PriorityType };
  isSelectedDate: boolean;
  isCorrectDate: boolean;
  additionalDetails: string;
  selectedJuridiction: string;
  isSelectedJuridiction: boolean;
  totalDocuments: number;
  isValidDocument: boolean;
  isDocument: boolean;
  files: Document[];
  documentErrors: boolean[];
  termOneError: boolean;
  checkboxOne: boolean;
  termTwoError: boolean;
  checkboxTwo: boolean;
  recipientErrors: RecipientErrors[][];
  saveModal: boolean;
  priorityName: string;
  juridiction: Array<Juridiction>;
  notarisationMethod: Array<NotarisationMethod>;
  loader: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class BookNotaryRequestController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getPriorityTimeoutId: any;
  getPriorityCallId: string = "";
  createRequestCallId: string = "";
  getJurisdictionCallId: string = "";
  getNotarisationMethodCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isSignatoryAvailable: [],
      onStep: 1,
      selectedService: "",
      tempSelectedDate: null,
      tempSelectedSession: "",
      isSelectService: false,
      selectedMethod: "",
      isSelectedMethod: false,
      selectedDate: null,
      selectedSession: "",
      isCalendarOpen: false,
      currentMonth: new Date(),
      priorities: {},
      isSelectedDate: false,
      isCorrectDate: false,
      additionalDetails: "",
      selectedJuridiction: "",
      isSelectedJuridiction: false,
      totalDocuments: 0,
      isValidDocument: false,
      isDocument: false,
      files: [],
      documentErrors: [],
      termOneError: false,
      checkboxOne: false,
      termTwoError: false,
      checkboxTwo: false,
      recipientErrors: [],
      saveModal: false,
      priorityName: "",
      notarisationMethod: [],
      juridiction: [],
      loader: false,
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const callId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let res = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (this.isValidResponse(res)) {
        switch (callId) {
          case this.createRequestCallId:
            this.props.setLoader(false);
            this.props.setModal(false);
            this.setState(
              {
                onStep: 1,
                selectedMethod: "",
                selectedDate: null,
                selectedJuridiction: "",
                selectedService: "",
                totalDocuments: 0,
                additionalDetails: "",
                files: [],
                selectedSession: "",
                checkboxOne: false,
                checkboxTwo: false,
                saveModal: true,
              },
              () => {
                this.props.allRequestAPI && this.props.allRequestAPI();
              }
            );
            break;
          case this.getJurisdictionCallId:
            this.setState({ juridiction: res });
            break;
          case this.getNotarisationMethodCallId:
            this.setState({ notarisationMethod: res });
            break;
          case this.getPriorityCallId:
            clearTimeout(this.getPriorityTimeoutId);
            if (this.isPriorityMethodArray(res)) this.handlePrioritySet(res);
            break;
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getJurisdictions();
    this.getNotarisationMethods();
    this.getPriorityApi(this.getDateRangeFromMonth(new Date()));
  }

  getDateRangeFromMonth(month: Date) {
    const today = new Date();
    const year = month.getFullYear();
    const monthIndex = month.getMonth();
    let firstDay;
    if (year === today.getFullYear() && monthIndex === today.getMonth())
      firstDay = addDays(today, 1).toISOString();
    else firstDay = new Date(year, monthIndex, 1).toISOString();
    const lastDay = new Date(year, monthIndex + 1, 0).toISOString();
    return { firstDay, lastDay };
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<S>,
    snapshot?: SS | undefined
  ): void {
    if (
      prevProps.isOpen !== this.props.isOpen &&
      this.props.editRequest !== undefined
    ) {
      const { editRequest } = this.props;
      const transformedFiles = editRequest.attributes.file_documents.map(
        (item) => ({
          id: item.doc_id,
          isfileMax: false,
          isInvalidSize: false,
          document: new File([], item.doc_name, { type: item.doc_type }),
          base64: item.doc_base_64,
          doc_size: item.doc_size,
          ellapsed: false,
          recipients_attributes: item.recipients
            ? item.recipients.map((recipient) => ({
                name: recipient.name,
                email: recipient.email,
                is_signatory: recipient.is_signatory,
              }))
            : [],
        })
      );
      this.setState({
        selectedService: editRequest.attributes.notary_service_type.toString(),
        selectedMethod:
          editRequest.attributes.notarisation_method_id.toString(),
        selectedDate: new Date(editRequest.attributes.date),
        tempSelectedDate: new Date(editRequest.attributes.date),
        selectedSession: editRequest.attributes.timing_slot,
        tempSelectedSession: editRequest.attributes.timing_slot,
        additionalDetails: editRequest.attributes.notes,
        selectedJuridiction: editRequest.attributes.jurisdiction_id.toString(),
        totalDocuments: editRequest.attributes.notarised_document,
        files: transformedFiles,
        priorityName: editRequest.attributes.priority,
      });
    }
  }

  isValidResponse = (res: ValidResponseType) => res && !res.errors;

  handleBack = () => {
    if (this.isOnStep1()) this.props.closeModal();
    else
      this.setState((prevState) => ({
        onStep: prevState.onStep - 1,
        checkboxOne: false,
        checkboxTwo: false,
      }));
  };

  apiCall = async (apiData: ApiCallInterface) => {
    let token = await getStorageData("token");
    const { contentType, method, endPoint, body } = apiData;
    const header = {
      "Content-Type": contentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  getJurisdictions = async () => {
    this.getJurisdictionCallId = await this.apiCall({
      contentType: configJSON.appJsonContentType,
      method: configJSON.getMethod,
      endPoint: configJSON.getjuridictionAPIEndPoint,
    });
  };

  getNotarisationMethods = async () => {
    this.getNotarisationMethodCallId = await this.apiCall({
      contentType: configJSON.appJsonContentType,
      method: configJSON.getMethod,
      endPoint: configJSON.getNotrisationMetodEndpoint,
    });
  };

  createRequest = async () => {
    this.props.setLoader(true);
    const transformData = (data: Array<Document>) => {
      return data.map((item: Document) => {
        return {
          base64: item.base64.includes(",")
            ? item.base64.split(",")[1]
            : item.base64,
          file: item?.document?.name,
          recipients_attributes: item.recipients_attributes.map(
            (recipient) => ({
              name: recipient.name,
              email: recipient.email,
              is_signatory: recipient.is_signatory,
            })
          ),
        };
      });
    };
    const transformedData = transformData(this.state.files);

    let requestData = {
      notary_request: {
        notary_service_type: this.state.selectedService,
        notarisation_method_id: this.state.selectedMethod,
        jurisdiction_id: this.state.selectedJuridiction,
        date: moment(this.state.selectedDate).format("YYYY-MM-DD"),
        notes: this.state.additionalDetails,
        notarised_document: this.state.totalDocuments,
        timing_slot: this.state.selectedSession,
        priority: this.state.priorityName,
        file_documents_attributes: transformedData,
      },
    };
    this.setState({ tempSelectedDate: null, tempSelectedSession: "" });
    this.createRequestCallId = await this.apiCall({
      contentType: configJSON.appJsonContentType,
      method:
        this.props.isNewRequestOrEditRequestOrInviteClient === "new"
          ? configJSON.postMethod
          : configJSON.putMethod,
      endPoint:
        this.props.isNewRequestOrEditRequestOrInviteClient === "new"
          ? configJSON.newNotaryEndPoint
          : configJSON.editNotaryRequestEndPoint +
            "/" +
            this.props.editRequest?.id,
      body: requestData,
    });
  };

  getPriorityApi = async (dates: DateRange) => {
    this.setState({ loader: true });
    const { firstDay, lastDay } = dates;
    let isResponseReceived = false;
    this.getPriorityTimeoutId = setTimeout(() => {
      if (!isResponseReceived)
        this.setState({ priorities: this.setInitialPriorities() });
    }, 30000);

    this.getPriorityCallId = await this.apiCall({
      contentType: configJSON.appJsonContentType,
      method: configJSON.getMethod,
      endPoint:
        configJSON.getPriorityAPIEndPoint +
        `?from_date=${firstDay}&to_date=${lastDay}`,
    });
  };

  getClassName = (isError: boolean) => (isError ? "errorStyle" : "textStyle");

  findFormTitle = () => {
    switch (this.props.isNewRequestOrEditRequestOrInviteClient) {
      case "new":
      case "invite":
        return "New Notary Request";
      case "edit":
        return "Edit Notary Request";
    }
  };

  validateStep3 = () => {
    const recipientErrors = this.state.files.map((docs) =>
      docs.recipients_attributes.map((recipient) => ({
        nameErr: recipient.name === "",
        emailErr: recipient.email === "",
        emailInvalidErr:
          recipient.email !== "" &&
          !configJSON.emailRegex.test(recipient.email),
      }))
    );
    const isSignatoryAvailable = this.state.files.map((file) =>
      file.recipients_attributes.some((recipient) => recipient.is_signatory)
    );
    this.setState(
      {
        recipientErrors: recipientErrors,
        isSignatoryAvailable: isSignatoryAvailable,
      },
      () => {
        const hasErrors = recipientErrors.some((docErrors) =>
          docErrors.some(
            (error) => error.nameErr || error.emailErr || error.emailInvalidErr
          )
        );
        if (!hasErrors && !this.state.isSignatoryAvailable.includes(false))
          this.createRequest();
      }
    );
  };

  validateStep2 = () => {
    let documentErrors = this.state.documentErrors;
    let termOneError;
    let termTwoError;
    if (this.state.files.length > 0) {
      for (let iValue = 0; iValue < this.state.files.length; iValue++) {
        if (this.state.files[iValue].document === null)
          documentErrors[iValue] = true;
        else documentErrors[iValue] = false;
      }
    }
    documentErrors = documentErrors.slice(0, this.state.files.length);
    if (!this.state.checkboxOne) {
      termOneError = true;
    } else {
      termOneError = false;
    }
    if (!this.state.checkboxTwo) {
      termTwoError = true;
    } else {
      termTwoError = false;
    }
    if (
      Object.values(documentErrors).includes(true) ||
      termOneError ||
      termTwoError
    ) {
      this.setState({
        documentErrors: documentErrors,
        termOneError: termOneError,
        termTwoError: termTwoError,
      });
    } else {
      this.setState((prevState) => ({ onStep: prevState.onStep + 1 }));
    }
  };

  validateStep1 = () => {
    const dateToday = new Date();
    const validationChecks = [
      {
        value:
          this.state.selectedService === "" ||
          this.state.selectedService === null,
        errorState: "isSelectService",
      },
      {
        value:
          this.state.selectedMethod === "" ||
          this.state.selectedMethod === null,
        errorState: "isSelectedMethod",
      },
      {
        value:
          this.state.selectedJuridiction === "" ||
          this.state.selectedJuridiction === null,
        errorState: "isSelectedJuridiction",
      },
      { value: this.state.totalDocuments === 0, errorState: "isDocument" },
      { value: this.state.selectedDate === null, errorState: "isSelectedDate" },
      {
        value:
          this.state.selectedDate !== null &&
          this.state.selectedDate < dateToday,
        errorState: "isCorrectDate",
      },
    ];
    this.setState(
      (prevState) => {
        const updatedState: { [key: string]: boolean | string } = {};
        validationChecks.forEach(({ value, errorState }) => {
          updatedState[errorState] = value;
        });
        return { ...prevState, ...updatedState };
      },
      () => {
        if (
          !this.state.isSelectService &&
          !this.state.isSelectedMethod &&
          !this.state.isSelectedJuridiction &&
          !this.state.isDocument &&
          this.state.selectedSession !== "" &&
          !this.state.isSelectedDate &&
          !this.state.isCorrectDate
        )
          this.setState((prevState) => ({ onStep: prevState.onStep + 1 }));
      }
    );
  };

  isOnStep1 = () => this.state.onStep === 1;
  isOnStep2 = () => this.state.onStep === 2;
  isOnStep3 = () => this.state.onStep === 3;

  handleNext = () => {
    if (this.isOnStep1()) this.validateStep1();
    else if (this.isOnStep2()) this.validateStep2();
    else if (this.isOnStep3()) this.validateStep3();
  };

  findNextButtonText = () => {
    if (
      this.isOnStep3() &&
      this.props.isNewRequestOrEditRequestOrInviteClient === "edit"
    )
      return "Update";
    else if (this.isOnStep3()) return "Create Request";
    else return "Next";
  };

  handleServiceSelection = (event: { target: { value: unknown } }) => {
    this.setState({
      selectedService: event.target.value as string,
      isSelectService: false,
    });
  };

  handleNotarisationMethodSelection = (event: {
    target: { value: unknown };
  }) => {
    this.setState({
      selectedMethod: event.target.value as string,
      isSelectedMethod: false,
    });
  };

  openCalendar = () => this.setState({ isCalendarOpen: true });

  handleCalendarSaveClick = () => {
    this.setState({
      isCalendarOpen: false,
      selectedDate: this.state.tempSelectedDate,
      selectedSession: this.state.tempSelectedSession,
    });
  };

  findDateValue = () => {
    if (this.state.selectedDate !== null && this.state.selectedSession !== "")
      return (
        format(this.state.selectedDate, "dd/MM/yyyy") +
        "-" +
        this.state.selectedSession
      );
    else if (this.state.selectedSession !== "")
      return this.state.selectedSession;
    else if (this.state.selectedDate !== null)
      return format(this.state.selectedDate, "dd/MM/yyyy");
    else return "";
  };

  handleCalendarCancelClick = () => {
    this.setState({
      isCalendarOpen: false,
      tempSelectedDate: this.props.editRequest
        ? new Date(this.props.editRequest.attributes.date)
        : this.state.selectedDate,
      tempSelectedSession: this.state.selectedSession,
      currentMonth: new Date(),
    });
  };

  leftArrow = () => {
    const {
      currentMonth,
      priorities,
    }: { currentMonth: Date; priorities: { [key: string]: any } } = this.state;
    const prevMonth: Date = subMonths(currentMonth, 1);
    const year: string = format(prevMonth, "yyyy");
    const month: string = format(prevMonth, "MM");
    const daysInMonth: number = new Date(
      parseInt(year),
      parseInt(month),
      0
    ).getDate();
    const currentDate: Date = new Date();
    const startOfCurrMonth: Date = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    if (prevMonth < startOfCurrMonth) {
      this.setState({ currentMonth: prevMonth });
      return;
    }
    const dayFoundInPriorities = Array.from(
      { length: daysInMonth },
      (_, day) => {
        const dayOfMonth: string = `${year}-${month}-${(day + 1)
          .toString()
          .padStart(2, "0")}`;
        return priorities[dayOfMonth];
      }
    ).some(Boolean);
    if (dayFoundInPriorities) {
      this.setState({ currentMonth: prevMonth });
      return;
    }
    this.setState({ currentMonth: prevMonth });
    this.getPriorityApi(this.getDateRangeFromMonth(prevMonth));
  };

  rightArrow = () => {
    const {
      currentMonth,
      priorities,
    }: { currentMonth: Date; priorities: { [key: string]: any } } = this.state;
    const nextMonth: Date = addMonths(currentMonth, 1);
    const year: string = format(nextMonth, "yyyy");
    const month: string = format(nextMonth, "MM");
    const daysInMonth: number = new Date(
      parseInt(year),
      parseInt(month),
      0
    ).getDate();
    const currDate: Date = new Date();
    const startOfCurrMonth: Date = new Date(
      currDate.getFullYear(),
      currDate.getMonth(),
      1
    );
    if (nextMonth < startOfCurrMonth) {
      this.setState({ currentMonth: nextMonth });
      return;
    }
    const dayFoundInPriorities = Array.from(
      { length: daysInMonth },
      (_, day) => {
        const dayOfMonth: string = `${year}-${month}-${(day + 1)
          .toString()
          .padStart(2, "0")}`;
        return priorities[dayOfMonth];
      }
    ).some(Boolean);
    if (dayFoundInPriorities) {
      this.setState({ currentMonth: nextMonth });
      return;
    }
    this.setState({ currentMonth: nextMonth });
    this.getPriorityApi(this.getDateRangeFromMonth(nextMonth));
  };

  isDecreasable = () => {
    return (
      (this.props.editRequest !== undefined &&
        this.state.totalDocuments <=
          this.props.editRequest.attributes.file_documents.length) ||
      this.state.totalDocuments <= 1
    );
  };

  isDeletable = (docIndex: number) => {
    return (
      this.props.isNewRequestOrEditRequestOrInviteClient !== "edit" ||
      (this.props.editRequest !== undefined &&
        docIndex + 1 > this.props.editRequest.attributes.file_documents.length)
    );
  };

  handleDateClick = (date: Date | null, priorityName: string) => {
    this.setState({
      tempSelectedDate: date,
      isSelectedDate: false,
      priorityName: priorityName,
    });
  };

  handleAdditionalDetailsChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => this.setState({ additionalDetails: event.target.value });

  handleJurisdictionSelection = (event: { target: { value: unknown } }) => {
    this.setState({
      selectedJuridiction: event.target.value as string,
      isSelectedJuridiction: false,
    });
  };

  addDocument = () => {
    let files = this.state.files;
    files.push({
      id: files.length + 1,
      document: null,
      base64: "",
      doc_size: 0,
      isfileMax: false,
      ellapsed: false,
      recipients_attributes: [
        {
          name: "",
          email: "",
          is_signatory: false,
        },
      ],
      isInvalidSize: false,
    });
    this.setState({ files: files });
  };

  onDocNumberChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const value = event?.target?.value;
    if (configJSON.regexForNumber.test(value) && !isNaN(Number(value))) {
      const totalDocuments = parseInt(value, 10);
      if (totalDocuments <= 10) {
        this.setState(
          {
            files: [],
            totalDocuments: totalDocuments,
            isDocument: false,
            isValidDocument: false,
          },
          () => {
            for (let iValue = 0; iValue < totalDocuments; iValue++) {
              this.addDocument();
            }
          }
        );
      }
    } else {
      this.setState({ isValidDocument: true, totalDocuments: 0 });
    }
  };

  increase = () => {
    if (this.state.totalDocuments < 10) {
      this.incrementValue();
      this.setState({ isDocument: false, isValidDocument: false });
    }
  };

  removeDocument = (indexValue: number) => {
    let files = this.state.files;
    files.splice(indexValue, 1);
    this.setState({ files: files });
  };

  decrementValue = (value: number) => {
    this.setState(
      (prevState) => {
        const newValue = prevState.totalDocuments - 1;
        return {
          totalDocuments: newValue >= 0 ? newValue : prevState.totalDocuments,
        };
      },
      () => {
        this.removeDocument(value);
      }
    );
  };

  handleDragOver = (event: React.DragEvent<HTMLElement>) =>
    event.preventDefault();

  handleDrop = (event: React.DragEvent<HTMLElement>, indexValue: number) => {
    event.preventDefault();
    if (event.dataTransfer.files) {
      const file = event.dataTransfer.files[0];

      const allowedExtensions = [
        "pdf",
        "docx",
        "jpg",
        "png",
        "jpeg",
        "docx",
        "doc",
      ];
      const fileExtension = file?.name?.split(".").pop()?.toLowerCase();

      const maxSizeInBytes = 2 * 1024 * 1024;
      let files = this.state.files;
      if (file.size > maxSizeInBytes) {
        this.state.files[indexValue].isfileMax = true;
        this.setState({ files: files });
        return;
      } else {
        files[indexValue].isfileMax = false;
      }
      if (!fileExtension || !allowedExtensions.includes(fileExtension)) {
        this.state.files[indexValue].isInvalidSize = true;
        this.setState({ files: files });
        return;
      }

      files[indexValue].document = file;
      this.setState({
        files: files,
      });
    }
  };

  handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    indexValue: number
  ) => {
    if (event?.target?.files) {
      const filesMax = event.target.files[0];
      const maxSizeInBytes = 2 * 1024 * 1024;

      let files = this.state.files;
      if (filesMax.size > maxSizeInBytes) {
        this.state.files[indexValue].isfileMax = true;
        this.setState({ files: files });
        return;
      } else {
        files[indexValue].isfileMax = false;
      }

      files[indexValue].document = filesMax;
      this.setState({ files: files });

      const reader = new FileReader();
      reader.readAsDataURL(filesMax);
      reader.onloadend = () => {
        const base64String = reader.result as string;
        files[indexValue].base64 = base64String;
      };
    }
  };

  deleteFile = (indexValue: number) => {
    let files = this.state.files;
    files[indexValue].document = null;
    this.setState({ files: files });
  };

  incrementValue = () => {
    this.setState((prevState) => {
      const newValue =
        prevState.totalDocuments < 10
          ? prevState.totalDocuments + 1
          : prevState.totalDocuments;
      return { totalDocuments: newValue };
    }, this.addDocument);
  };

  handleCheckbox1Click = () =>
    this.setState({ checkboxOne: !this.state.checkboxOne });

  handleCheckbox2Click = () =>
    this.setState({ checkboxTwo: !this.state.checkboxTwo });

  addRecipient = (docIndex: number) => {
    let files = this.state.files;
    const dataItem = { name: "", email: "", is_signatory: false };

    if (files[docIndex].recipients_attributes.length < 4) {
      files[docIndex].recipients_attributes.push(dataItem);
      this.setState({ files: files });
    } else {
      alert("You can only add up to 4 recipients.");
    }
  };

  handleEllapsed = (docIndex: number) => {
    let files = [...this.state.files];
    files[docIndex].ellapsed = !files[docIndex].ellapsed;
    this.setState({ files: files });
  };

  removeRecipient = (docIndex: number, recipientIndex: number) => {
    let files = this.state.files;
    files[docIndex].recipients_attributes.splice(recipientIndex, 1);
    this.setState({ files: files });
  };

  handleRecipientNameChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    docIndex: number,
    recipientIndex: number
  ) => {
    if (
      configJSON.nameRegex.test(event.target.value) ||
      event.target.value === ""
    ) {
      let files = this.state.files;
      files[docIndex].recipients_attributes[recipientIndex].name =
        event.target.value;
      if (
        this.state.recipientErrors[docIndex] &&
        this.state.recipientErrors[docIndex][recipientIndex]
      ) {
        this.state.recipientErrors[docIndex][recipientIndex].nameErr = false;
      }
      this.setState({ files: files });
    }
  };

  handleRecipientEmailChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    docIndex: number,
    recipientIndex: number
  ) => {
    let files = this.state.files;
    files[docIndex].recipients_attributes[recipientIndex].email =
      event.target.value;
    if (
      this.state.recipientErrors[docIndex] &&
      this.state.recipientErrors[docIndex][recipientIndex]
    ) {
      this.state.recipientErrors[docIndex][recipientIndex].emailErr = false;
    }
    this.setState({ files: files });
  };

  handleSignatory = (docIndex: number, recipientIndex: number) => {
    this.state.isSignatoryAvailable[docIndex] = true;
    let files = this.state.files;
    files[docIndex].recipients_attributes[recipientIndex].is_signatory =
      !files[docIndex].recipients_attributes[recipientIndex].is_signatory;
    this.setState({ files: files });
  };

  yesButtonClick = () => {
    this.setState(
      {
        onStep: 1,
        isCalendarOpen: false,
        selectedDate: null,
        tempSelectedDate: null,
        selectedJuridiction: "",
        selectedMethod: "",
        selectedService: "",
        totalDocuments: 0,
        additionalDetails: "",
        selectedSession: "",
        tempSelectedSession: "",
        files: [],
        isSelectedDate: false,
        isCorrectDate: false,
        isValidDocument: false,
        isSelectedMethod: false,
        isSelectService: false,
        isSelectedJuridiction: false,
        isDocument: false,
        checkboxOne: false,
        checkboxTwo: false,
      },
      () => this.props.yesButtonClick()
    );
  };

  closeSuccessModal = () => this.setState({ saveModal: false });

  setInitialPriorities(): Priorities {
    const priorities: Priorities = {};
    const indices = Array.from({ length: 31 }, (intial, itemData) => itemData);
    let priorityLimit = 0;
    const today = new Date();
    const tomorrow = addDays(today, 1);

    indices.forEach((value) => {
      const date = addDays(tomorrow, value - 1);
      const dateString = format(date, "yyyy-MM-dd");
      const isWeekend = getDay(date) === 0 || getDay(date) === 6;
      if (isWeekend) {
        priorities[dateString] = "Super Priority";
      } else {
        priorityLimit += 1;
        if (priorityLimit <= 10) {
          priorities[dateString] = "Priority";
        } else {
          priorities[dateString] = "Standard";
        }
      }
    });
    this.setState({ loader: false });
    return priorities;
  }

  isPriorityMethodArray = (obj: any): obj is PriorityMethodArray => {
    return (
      Array.isArray(obj) &&
      obj.every(
        (item) =>
          typeof item === "object" &&
          item !== null &&
          typeof item.priority === "number" &&
          typeof item.date === "string"
      )
    );
  };

  handlePrioritySet(resArray: PriorityMethodArray) {
    resArray.forEach((res) => {
      const priorityCount: number = res.priority || 0;
      const date: any = format(parseISO(res.date), "yyyy-MM-dd") || "";
      let priority: PriorityType;
      switch (priorityCount) {
        case 0:
          priority = "Standard";
          break;
        case 1:
          priority = "Priority";
          break;
        case 2:
          priority = "Super Priority";
          break;
        case 3:
          priority = "Not Available";
          break;
        default:
          priority = "Standard";
          break;
      }
      if (date.trim() !== "") {
        this.setState((prevState) => ({
          priorities: {
            ...prevState.priorities,
            [date]: priority,
          },
        }));
      }
    });
    this.setState({ loader: false });
  }
  // Customizable Area End
}
