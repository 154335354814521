Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.GETApiMethod = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.POSTAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.startLabel = "Start";
exports.labelTitleText = "KnowYourCustomerKycVerification";
exports.labelBodyText = "KnowYourCustomerKycVerification Body";
exports.getonfidoApi="https://api.onfido.com/v3/sdk_token";
exports.verificationHeading="Select the type of verification you would like to proceed with.";
exports.requestedDoc="List of requested verifications";
exports.countryCodeAPIEndPoint = "bx_block_menu_ordering/country_codes";
exports.btnExampleTitle = "CLICK ME";
exports.getCountryAPI = "account_block/countries";
exports.kycDocumentList = "bx_block_knowyourcustomerkycverification2/onfidos/get_kyc_document";
exports.kycCreateApi = "bx_block_knowyourcustomerkycverification2/onfidos/kyc_create";
exports.getKycRequestStatus = "bx_block_knowyourcustomerkycverification2/onfidos/get_kyc_request";
exports.endUserApplicantCreate = "bx_block_knowyourcustomerkycverification2/onfidos/applicant_create";
exports.workflow_id = "b42cf165-031f-4792-a57e-eea4fa10c4e2";
exports.startLabel = "Start";
exports.report = "Download Report";
exports.kycTitle = "KYC Request";
// Customizable Area End