Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.applicationJsonContentType = "application/json";
exports.getMethod = "GET";
exports.patchMethod = "PATCH";

exports.getNotificationsApiEndpoint1 = "bx_block_notifications/notifications?page=";
exports.getNotificationsApiEndpoint2 = "&per_page=15";
exports.markAsReadApiEndpoint = "bx_block_notifications/notifications/mark_as_read";
// Customizable Area End