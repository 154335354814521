Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "settings";
exports.labelBodyText = "settings Body";

exports.btnExampleTitle = "CLICK ME";
exports.userProfileDetailsApiEndpoint = "bx_block_profile/profiles/get_profile";
exports.stripeConnectEndpoint = "bx_block_stripe_integration/stripe_connect";
exports.checkStripeConnectApiEndpoint = "bx_block_stripe_integration/check_stripe_setup";


exports.getSalesTaxtApiContentType = "application/json";
exports.getSalesTaxtApiMethod = "GET";
exports.getSalesTaxtAPIEndPoint ="bx_block_sales_tax/show?";


exports.getSalesTaxtEditApiContentType = "application/json";
exports.getSalesTaxtEditApiMethod = "POST";
exports.getSalesTaxtEditAPIEndPoint ="bx_block_sales_tax/create_update";
exports.googleCalendarClientId =
  "970345475312-klp702ahgsvvnt3eun75igej2ioq8cjj.apps.googleusercontent.com";
exports.googleCalendarScope = "https://www.googleapis.com/auth/calendar";
exports.getEventApiMethod = "GET";
exports.addEventApiMethod = "POST";
exports.googleAuthUserEndpoint = "https://www.googleapis.com/oauth2/v2/userinfo"
exports.checkCondition_1 = "Must be at least 8 characters or more.";
exports.google_auth_para1 = "You need to authenticate your Google account to sync your Google calendar here. On clicking ‘ Authenticate Google’ you will be redirected to your Google Account page."
exports.outlookAuthUserInfoEndpoint = "https://graph.microsoft.com/v1.0/me";
exports.outlookUserProfilePicsEndpoint = "https://graph.microsoft.com/v1.0/me/photo/$value";
exports.google_auth_para2 = "I agree to the providers terms of use and privacy policy and understand that the rights to use this product do not come from renotary";
exports.outlook_auth_para1 = "You need to authenticate your Outlook account to sync your Outlook calendar here. On clicking ‘Authenticate Outlook’ you will be redirected to your Outlook Account page."
exports.outlook_auth_para2 = "I agree to the providers terms of use and privacy policy and understand that the rights to use this product do not come from renotary"
exports.delete_account_para1 = "Please note that once the account is deleted, all the data that is available now will be erased and you won’t be able to retrieve your account any longer."
exports.delete_account_para2 = "If you still wish to continue with account deletion, please enter your password below to proceed with deletion."
exports.changePasswordApiEndpoint = "bx_block_profile/change_password";
exports.checkPasswordForDeleteApiContentType = "application/json"
exports.checkPasswordForDeleteApiEndPoint = "login/login";
exports.checkPasswordForDeleteApiMethod = "POST";
exports.getProfileContentType = "application/json";
exports.getuserprofileEndPoint = "bx_block_profile/profiles/get_profile";
exports.getProfileApiMethodType = "GET";
exports.deleteAccountApiContentType = "application/json";
exports.deleteAccountApiEndPoint = "account_block/accounts/delete_account";
exports.deleteAccountApiMethod = "DELETE";
exports.disconnectStripeApiEndpoint = "bx_block_stripe_integration/stripe_disconnect";
exports.updateCalendarTokenEndpoint = "account_block/accounts/set_calendar_token_toogle_sync?";
exports.removeCalendarTokenEndpoint = "account_block/accounts/remove_calendar?"
// Customizable Area End