
export const insta =require("../assets/insta.png")
export const linkedin =require("../assets/linkedin.png")
export const youtube =require("../assets/youtube.png")
export const facebook =require("../assets/facebook.png")
export const cross =require("../assets/cross.png")
export const tictok =require("../assets/tictok.png")
export const ICO =require("../assets/ICO.png")
export const ISO =require("../assets/ISO.png")
export const UKAS =require("../assets/UKAS.png")
export const coming =require("../assets/coming.png")
export const crown =require("../assets/crown.png")
export const logo1 =require("../assets/logo1.png")
export const builder =require("../assets/builder.png")
export const download =require("../assets/download.png")
export const zoom =require("../assets/zoom.png")
export const GBC =require("../assets/GBC.png")
export const fast =require("../assets/fast.png")
export const rating =require("../assets/rating.png")
export const greenRating =require("../assets/greenRating.png")
export const appStore =require("../assets/appStore.png")
export const logo = require("../assets/logo.svg");
export const dashboardIcon = require("../assets/category.svg");
export const icon2 = require("../assets/image_1.svg");
export const icon3 = require("../assets/image_2.svg");
export const icon4 = require("../assets/image_3.svg");
export const icon5 = require("../assets/image_4.svg");
export const icon6 = require("../assets/image_6.svg");
export const icon7 = require("../assets/image_5.svg");
export const icon8 = require("../assets/image_7.svg");
export const logoWhite = require("../assets/logoWhite.png");
export const successImage = require("../assets/success.png");
export const failureImage = require("../assets/failure.png");


// export const googlePlay =require("..src/assets/googlePlay.png")


export const googlePlay = require("../assets/googlePlay.png")
export const warningImage = require("../assets/warning.png");