export const logoImg = require('../assets/logo.png');
export const bellIcon = require("../assets/bellICon.png");
export const localProfile = require("../assets/acount.png");
export const logoBlueImg = require('../assets/logoBlue.png');
export const service = require("../assets/service.png");
export const cancelImage = require("../assets/5fa4e37863a6766904897d4629041c86.png");
export const successImage = require("../assets/success.png");
export const failureImage = require("../assets/failure.png");
export const amazonPay = require("../assets/amazonPay.png");
export const americanExpress = require("../assets/americanExpress.png");
export const applePay = require("../assets/applePay.png");
export const gPay = require("../assets/gPay.png");
export const maestro = require("../assets/maestro.png");
export const mastercard = require("../assets/mastercard.png");
export const payPal = require("../assets/payPal.png");
export const unionPay = require("../assets/unionPay.png");
export const visa = require("../assets/visa.png");
export const zoom = require("../assets/zoom.png");
export const verified = require("../assets/verified.png");
export const emptyFolder = require("../assets/emptyFolder.png");
export const groupImg = require("../assets/grouppage.png")
export const crossIcon = require("../assets/cross.png")
export const calenderIcon = require("../assets/calendar_today_24px.png")