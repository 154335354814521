import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import React, { createRef } from "react";
import { failureImage } from "./assets";
import Slider from "react-slick";
import {
  getStorageData,
  setStorageData,
} from "../../../framework/src/Utilities";
import moment from "moment";

interface IRequest {
  id: string;
  type: string;
  attributes: Attributes;
  relationships: Relationships;
}

interface Attributes {
  status: string;
  notary_id: number | null;
  notarisation_method_id: number;
  notary_service_name: string;
  priority: string;
  notary_service_type: number;
  jurisdiction_id: number;
  date: string;
  notes: string;
  notarised_document: number;
  timing_slot: string;
  file_documents: FileDocumentsEntity[];
  juridiction: Juridiction;
  notarisation_method: NotarisationMethod;
  quote_statuses_id: number;
}

interface Juridiction {
  id: number;
  jurisdiction: string;
  created_at: string;
  updated_at: string;
}

interface NotarisationMethod {
  id: number;
  notarisation_method: string;
  created_at: string;
  updated_at: string;
}

interface FileDocumentsEntity {
  doc_type: string;
  doc_name: string;
  doc_id: number;
  doc_base_64: string;
  doc_size: number;
  signatory_count: number;
  doc_file_url: string;
  recipients?: RecipientsEntity[] | null;
}

interface RecipientsEntity {
  created_at: string;
  name: string;
  id: number;
  file_document_id: number;
  updated_at: string;
  is_signatory: boolean;
  email: string;
  is_notary: boolean;
  signed: boolean;
}

interface Relationships {
  notarisation_method: JurisdictionOrNotaryOrNotarisationMethodOrAccount;
  jurisdiction: JurisdictionOrNotaryOrNotarisationMethodOrAccount;
  account: JurisdictionOrNotaryOrNotarisationMethodOrAccount;
  notary: JurisdictionOrNotaryOrNotarisationMethodOrAccount;
}

interface JurisdictionOrNotaryOrNotarisationMethodOrAccount {
  data: Data | null;
}

interface Data {
  type: string;
  id: string;
}

interface ApiCallInterface {
  contentType?: string;
  method?: string;
  endPoint?: string;
  body?: object;
}

interface ValidResponseType {
  message: object;
  data: object;
  errors: string;
  status: number;
}

interface DataofService {
  id: string;
  type: string;
  attributes: {
    id: number;
    service_icon?: {
      url: string;
    };
    service_name: string;
    service_description: string;
    is_selected: boolean;
  };
}

interface IsProfileCompleteData {
  is_phone_number: boolean;
  is_address: boolean;
  is_notary_service: boolean;
  is_payment_details: boolean;
  is_vat_sales_tax: boolean;
  is_qualified_signature: boolean;
}
// Customizable Area End

export const config = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  cancelRequestStatus: string;
  isSuccessFailModalOpen: boolean;
  successFailModalImage: string;
  successFailModalText: string;
  successFailModalTextColor: string | undefined;
  successFailModalSubText: string;
  successFailModalSubText2: string;
  successFailModalButtonText: string;
  type: string;
  rows: IRequest[];
  tabValue: number;
  endUserRequests: IRequest[];
  endUserInvites: IRequest[];
  notaryUserOngoingRequests: IRequest[];
  notaryUserNewRequests: IRequest[];
  notaryUserInvites: IRequest[];
  isActionBoxActive: boolean;
  actionBoxIndex: number | null;
  allRequest: number;
  modalOpen: boolean;
  progressRequest: number;
  completeCount: number;
  serviceData: DataofService[];
  cancelReqModal: boolean;
  selectedDateOnSave: string | Date | null;
  roleId: number;
  loader: boolean;
  outgoingCount: number;
  cancelNotaryRequestModal: boolean;
  cancelNotaryRequestSubText: string | undefined;
  cancellationChargesErrorText: string;
  currentCancelRequest: string;
  isRequestNewOrEditOrInvite: string;
  editRequest: IRequest | undefined;
  isProfileCompleteData: IsProfileCompleteData | null;
  isUserActive: boolean;
  isInviteFormModalOpen: boolean;
  // Customizable Area End
}
interface SS {}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getRequestCountsCallId: string = "";
  getAllRequestsCallId: string = "";
  getServicesCallId: string = "";
  getProfileCallId: string = "";
  getCancellationChargesCallId: string = "";
  cancelRequestCallId: string = "";
  getIsProfileCompleteCallId: string = "";
  zoomMeetingCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      cancelRequestStatus: "",
      isSuccessFailModalOpen: false,
      successFailModalImage: "",
      successFailModalText: "",
      successFailModalTextColor: undefined,
      successFailModalSubText: "",
      successFailModalSubText2: "",
      successFailModalButtonText: "",
      type: "",
      allRequest: 0,
      progressRequest: 0,
      completeCount: 0,
      modalOpen: false,
      selectedDateOnSave: "",
      loader: false,
      cancelReqModal: false,
      serviceData: [],
      rows: [],
      endUserRequests: [],
      endUserInvites: [],
      roleId: 0,
      notaryUserOngoingRequests: [],
      notaryUserNewRequests: [],
      notaryUserInvites: [],
      tabValue: 0,
      isActionBoxActive: false,
      actionBoxIndex: null,
      outgoingCount: 0,
      cancelNotaryRequestModal: false,
      cancelNotaryRequestSubText: undefined,
      cancellationChargesErrorText: "",
      currentCancelRequest: "",
      isRequestNewOrEditOrInvite: "new",
      editRequest: undefined,
      isProfileCompleteData: null,
      isUserActive: false,
      isInviteFormModalOpen: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.setState({ loader: true });
    this.getToken();
    this.getProfile();
    this.getRequestCounts();
    this.allRequestAPI();
    this.getServicesAPI();
    this.getIsProfileComplete();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let callId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let res = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (this.isValidResponse(res)) {
        switch (callId) {
          case this.getProfileCallId:
            this.setState({ roleId: res.data.attributes.role_id });
            break;
          case this.zoomMeetingCallId:
            if (res.zoom_meetings.meeting.join_url)
              window.open(window.location.origin + "/MeetSDK", "_blank");
            break;
          case this.getRequestCountsCallId:
            this.setState({
              allRequest: res.all_request_count,
              progressRequest: res.in_progress_request_count,
              completeCount: this.findCompleteCount(
                res.user_completed_request_count,
                res.notary_completed_request_count
              ),
            });
            break;
          case this.getAllRequestsCallId:
            this.handleAllRequestsRes(res);
            break;
          case this.getServicesCallId:
            this.setState({ serviceData: res.data });
            break;
          case this.getCancellationChargesCallId:
            this.setState({
              loader: false,
              cancellationChargesErrorText: "",
              cancelNotaryRequestModal: true,
              cancelNotaryRequestSubText: `Cancellation charges will be applied of £${parseFloat(
                res.cancellation_charges
              ).toFixed(2)}`,
            });
            break;
          case this.cancelRequestCallId:
            this.allRequestAPI();
            break;
          case this.getIsProfileCompleteCallId:
            this.handleIsProfileCompleteRes(res.data);
            break;
        }
      } else {
        switch (callId) {
          case this.getCancellationChargesCallId:
            this.setState({
              loader: false,
              isSuccessFailModalOpen: true,
              successFailModalImage: failureImage,
              successFailModalText: "Request Cancellation Failed",
              successFailModalTextColor: "#FF0000",
              successFailModalSubText: res.errors,
              successFailModalButtonText: "OK",
            });
            break;
          case this.getAllRequestsCallId:
            this.setState({ rows: [] });
            break;
        }
      }
    }

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      setStorageData("token", token);
    }
    // Customizable Area End
  }
  // Customizable Area Start
  handleAllRequestsRes = (res: any) => {
    this.setState(
      { endUserRequests: [], loader: false },
      this.getRequestCounts
    );

    const {
      end_user_notary_requests,
      new_notary_requests,
      notary_ongoing_requests,
    } = res;

    const requests = this.isEndUser()
      ? end_user_notary_requests?.data
      : new_notary_requests?.data;

    if (requests?.length > 0) {
      this.setState({
        endUserRequests: requests,
        rows: requests,
      });
    }

    if (notary_ongoing_requests?.data?.length > 0) {
      this.setState({
        notaryUserOngoingRequests: notary_ongoing_requests.data,
      });
    }
    if (new_notary_requests?.data?.length > 0) {
      this.setState({
        notaryUserNewRequests: new_notary_requests.data,
        rows: new_notary_requests.data,
      });
    }
  };

  standardizeRequestCounts = (count: number) =>
    count < 10 ? "0" + count.toString() : count;

  findCompleteCount = (endUserCount: number, notaryUserCount: number) =>
    this.isEndUser() ? endUserCount : notaryUserCount;

  navigateToEditProfile = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "UserProfile"
    );
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      isEdit: true,
    });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  };

  navigateToSettings = (tabValue: number) => {
    const navigationData = { tabValue: tabValue };
    this.navigateTo("Settings", navigationData);
  };

  navigateToRequestDetails(requestId: string, isSubmitQuote: boolean = false) {
    setStorageData("notaryRequestId", requestId);
    setStorageData("isSubmitQuoteOpen", isSubmitQuote);
    this.props.navigation.navigate("RequestDetails", { id: requestId });
  }

  setIsRequestNewOrEditOrInvite = (value: string, requestId: string = "") => {
    this.setState(
      {
        isRequestNewOrEditOrInvite: value,
        editRequest: this.state.rows.find((row) => row.id === requestId),
      },
      () => {
        this.setBookRequestModal(true);
      }
    );
  };

  handleTabItemChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    const rowsLookup: { [key: number]: IRequest[] } = this.isNotaryUser()
      ? {
          0: this.state.notaryUserNewRequests,
          1: this.state.notaryUserOngoingRequests,
          2: this.state.notaryUserInvites,
        }
      : {
          0: this.state.endUserRequests,
          1: this.state.endUserInvites,
        };

    this.setState({
      tabValue: newValue,
      rows: rowsLookup[newValue],
    });
  };

  closeCancelRequestModal = () => {
    this.setState({
      cancelNotaryRequestModal: false,
      currentCancelRequest: "",
      cancelRequestStatus: "",
      cancelNotaryRequestSubText: undefined,
      cancellationChargesErrorText: "",
    });
  };

  cancelRequest = async () => {
    this.cancelRequestCallId = await this.apiCall({
      contentType: config.appJsonContentType,
      method: config.putMethod,
      endPoint:
        config.cancelRequestEndpoint1 +
        this.state.currentCancelRequest +
        config.cancelRequestEndpoint2,
    });
    this.setState({
      cancelNotaryRequestModal: false,
      currentCancelRequest: "",
      cancelRequestStatus: "",
      cancelNotaryRequestSubText: undefined,
      cancellationChargesErrorText: "",
    });
  };

  setLoader = (value: boolean) => this.setState({ loader: value });

  setBookRequestModal = (value: boolean) => this.setState({ modalOpen: value });

  openCancelRequestModal = (requestId: string, requestStatus: string) => {
    this.setState({
      currentCancelRequest: requestId,
      cancelRequestStatus: requestStatus,
    });
    if (this.isRequestPending(requestStatus)) {
      this.setState({
        cancellationChargesErrorText: "",
        cancelNotaryRequestSubText: undefined,
        cancelNotaryRequestModal: true,
      });
    } else {
      this.setState({ loader: true });
      this.getCancellationCharges();
    }
  };

  getCancellationCharges = async () => {
    this.getCancellationChargesCallId = await this.apiCall({
      contentType: config.appJsonContentType,
      method: config.getMethod,
      endPoint:
        config.getCancellationChargesEndpoint1 +
        this.state.currentCancelRequest +
        config.getCancellationChargesEndpoint2,
    });
  };

  //istanbul ignore next
  disableBackButton() {
    window.history.pushState(null, "", window.location.href);

    window.onpopstate = function () {
      window.history.pushState(null, "", window.location.href);
    };
  }

  sliderRef = createRef<Slider>();

  handleNext = () => {
    if (this.sliderRef.current) this.sliderRef.current.slickNext();
  };

  handlePrev = () => {
    if (this.sliderRef.current) this.sliderRef.current.slickPrev();
  };

  isValidResponse = (res: ValidResponseType) => res && !res.errors;

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  getUrgencyClass = (urgency: string) => {
    switch (urgency) {
      case "priority":
        return "priority";
      case "standard":
        return "standard";
      case "super priority":
        return "superPriority";
      default:
        return "";
    }
  };

  getStatusClass = (status: string) => {
    switch (status) {
      case "completed":
        return "completed";
      case "cancelled":
        return "cancelled";
      case "pending":
        return "pending";
      case "in progress":
        return "inprogress";
      default:
        return "";
    }
  };

  getNotarisationMethod = (methodId: number) => {
    switch (methodId) {
      case 1:
        return "REN";
      case 2:
        return "RIN";
      default:
        return "REIN";
    }
  };

  findToolTiptext = (methodId: number) => {
    switch (methodId) {
      case 1:
        return "Remote Electronic Notarisation (Fully Automated and e-signatures)";
      case 2:
        return "Remote Ink Notarisation (Completely Manual; User and Notary signs it physically)";
      default:
        return "Remote Electronic Ink Notarisation (Partially Automated; User digitally signs it and Notary signs it physically)";
    }
  };

  openActionBox = (indexValue: number) =>
    this.setState({ isActionBoxActive: true, actionBoxIndex: indexValue });

  closeActionBox = () =>
    this.setState({ isActionBoxActive: false, actionBoxIndex: null });

  isEndUser = () => this.state.roleId === 1;

  isNotaryUser = () => this.state.roleId === 2;

  isRequestPending = (status: string) => status.toLowerCase() === "pending";

  isRequestInProgress = (status: string) =>
    status.toLowerCase() === "in progress";

  isEditActionButtonShown = (request: IRequest) =>
    this.isEndUser() && this.isRequestPending(request.attributes.status);

  isCancelActionButtonShown = (request: IRequest) =>
    this.isEndUser() &&
    (this.isRequestPending(request.attributes.status) ||
      this.isRequestInProgress(request.attributes.status));

  isMessageActionButtonShown = (request: IRequest) =>
    this.isRequestInProgress(request.attributes.status);

  isMeetingActionButtonShown = (request: IRequest) =>
    this.isRequestInProgress(request.attributes.status);

  isSubmitQuoteActionButtonShown = (request: IRequest) =>
    this.isNotaryUser() &&
    (request.attributes.quote_statuses_id === 2 ||
      request.attributes.quote_statuses_id === null);

  isWithdrawQuoteActionButtonshown = (request: IRequest) =>
    this.isNotaryUser() && request.attributes.quote_statuses_id === 1;

  closeRequestModal = () => {
    this.setBookRequestModal(!this.state.modalOpen);
    this.setState({ cancelReqModal: true });
  };

  requestModalNoButtonClick = () => {
    this.setBookRequestModal(!this.state.modalOpen);
    this.setState({ cancelReqModal: false });
  };

  requestModalYesButtonClick = () => this.setState({ cancelReqModal: false });

  apiCall = async (apiData: ApiCallInterface) => {
    let token = await getStorageData("token");
    const { contentType, method, endPoint, body } = apiData;
    const header = {
      "Content-Type": contentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  navigateTo = (screen: string, data?: object) => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), screen);
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    if (data) {
      const raiseMsg = new Message(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      raiseMsg.addData(getName(MessageEnum.CustomDataMessage), data);
      msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMsg);
    }
    this.send(msg);
  };

  getRequestCounts = async () => {
    this.getRequestCountsCallId = await this.apiCall({
      contentType: config.appJsonContentType,
      method: config.getMethod,
      endPoint: config.allRequestCountApiEndpoint,
    });
  };

  allRequestAPI = async () => {
    this.getAllRequestsCallId = await this.apiCall({
      contentType: config.appJsonContentType,
      method: config.getMethod,
      endPoint: config.getAllNotaryRequestApiEndpoint,
    });
  };

  getServicesAPI = async () => {
    this.getServicesCallId = await this.apiCall({
      contentType: config.appJsonContentType,
      method: config.getMethod,
      endPoint: config.allServiceApiEndpoint,
    });
  };

  getProfile = async () => {
    this.getProfileCallId = await this.apiCall({
      contentType: config.appJsonContentType,
      method: config.getMethod,
      endPoint: config.getProfileEndPoint,
    });
  };

  findDateColor = (notaryRequest: IRequest) => {
    const receivedDate = new Date(notaryRequest.attributes.date);
    const dateToday = new Date();
    receivedDate.setHours(0, 0, 0, 0);
    dateToday.setHours(0, 0, 0, 0);
    return receivedDate < dateToday &&
      this.isEndUser() &&
      this.isRequestPending(notaryRequest.attributes.status)
      ? "red"
      : "#011342";
  };

  formatDate = (date: string) => moment(date).format("DD/MM/yyyy");

  isProfileIncomplete = () => {
    const { isProfileCompleteData } = this.state;

    const isPhoneNumberMissing = !isProfileCompleteData?.is_phone_number;
    const isNotaryServiceMissing = !isProfileCompleteData?.is_notary_service;
    const isAddressMissing = !isProfileCompleteData?.is_address;
    const isPaymentDetailMissing = !isProfileCompleteData?.is_payment_details;
    const isVATSalesTaxMissing = !isProfileCompleteData?.is_vat_sales_tax;
    const isQualifiedSignMissing =
      !isProfileCompleteData?.is_qualified_signature;

    return (
      this.isNotaryUser() &&
      (isPhoneNumberMissing ||
        isNotaryServiceMissing ||
        isAddressMissing ||
        isPaymentDetailMissing ||
        isVATSalesTaxMissing ||
        isQualifiedSignMissing)
    );
  };

  isActiveNotaryUser = () => this.isNotaryUser() && this.state.isUserActive;

  getIsProfileComplete = async () => {
    this.getIsProfileCompleteCallId = await this.apiCall({
      contentType: config.appJsonContentType,
      method: config.getMethod,
      endPoint: "bx_block_profile/complete_profile",
    });
  };

  handleIsProfileCompleteRes = (res: IsProfileCompleteData) => {
    const {
      is_phone_number,
      is_address,
      is_notary_service,
      is_payment_details,
      is_vat_sales_tax,
      is_qualified_signature,
    } = res;
    if (
      is_phone_number &&
      is_address &&
      is_notary_service &&
      is_payment_details &&
      is_vat_sales_tax &&
      is_qualified_signature
    ) {
      this.setState({ isUserActive: true });
    }
    this.setState({ isProfileCompleteData: res });
  };

  countStatus = () => {
    const hasRequests = this.state.allRequest !== 0;
    const profileData = this.state.isProfileCompleteData;
    const hasCompleteProfile =
      profileData &&
      profileData?.is_phone_number === true &&
      profileData?.is_address === true &&
      profileData?.is_notary_service === true;
    return this.isNotaryUser() && hasRequests && hasCompleteProfile;
  };

  getZoomApi = async (requestId: string) => {
    this.zoomMeetingCallId = await this.apiCall({
      method: config.getMethod,
      contentType: config.appJsonContentType,
      endPoint: config.zoomEndPoint + requestId,
    });
  };
  // Customizable Area End
}
