export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgChngPasswordIcon = require("../assets/change-pass.png");
export const imgPenIcon = require("../assets/pan.png");
export const imgPaymentIcon = require("../assets/payment.png");
export const imgTaxIcon = require("../assets/vat.png");
export const imgSyncIcon = require("../assets/sync.png")
export const Success = require("../assets/tick-circle.svg")
export const Warning = require("../assets/Warning.svg");
export const error = require("../assets/error.svg");
export const SuccessIcon  = require("../assets/image_(3).png")

