import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
export const config = require("./config");
import { getStorageData } from "../../../framework/src/Utilities";
import * as yup from "yup";
import {
  format,
  addMonths,
  subMonths,
  addDays,
  getDay,
  parseISO,
} from "date-fns";

interface ApiCallInterface {
  contentType?: string;
  method?: string;
  endPoint?: string;
  body?: object;
}

interface ValidResponseType {
  message: object;
  data: object;
  errors: string;
}

interface DataofService {
  id: string;
  type: string;
  attributes: {
    id: number;
    service_icon?: {
      url: string;
    };
    service_name: string;
    service_description: string;
    is_selected: boolean;
  };
}

interface NotarisationMethod {
  id: number;
  notarisation_method: string;
  created_at: string;
  updated_at: string;
}

interface PriorityMethod {
  priority: number;
  date: any;
}

type PriorityMethodArray = PriorityMethod[];

type PriorityType =
  | "Standard"
  | "Priority"
  | "Super Priority"
  | "Not Available";

interface Priorities {
  [key: string]: PriorityType;
}

interface DateRange {
  firstDay: string;
  lastDay: string;
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  isOpen: boolean;
  closeModal: () => void;
  allRequestAPI?: () => void;
  serviceData: Array<DataofService>;
  setLoader: (value: boolean) => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isEndTimePickerOpen: boolean;
  isStartTimePickerOpen: boolean;
  tempSelectedDate: Date | null;
  tempSelectedSession: string;
  currentMonth: Date;
  calendarOpen: boolean;
  selectedSession: string;
  priorities: { [key: string]: PriorityType };
  selectedDate: Date | null;
  isSelectedDate: boolean;
  loader: boolean;
  priorityName: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class InviteFormController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiCallTimeoutId: any;
  getPriorityApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isStartTimePickerOpen: false,
      isEndTimePickerOpen: false,
      currentMonth: new Date(),
      tempSelectedSession: "",
      calendarOpen: false,
      tempSelectedDate: null,
      selectedDate: null,
      priorities: {},
      selectedSession: "",
      isSelectedDate: false,
      loader: false,
      priorityName: "",
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const callId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let res = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (this.isValidResponse(res)) {
        if (callId === this.getPriorityApiCallId) {
          clearTimeout(this.apiCallTimeoutId);
          if (
            callId === this.getPriorityApiCallId &&
            this.isPriorityMethodArray(res)
          ) {
            this.handlePrioritySet(res);
          }
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getPriorityApi(this.getDateRangeFromMonth(new Date()));
  }

  isValidResponse = (responseJson: ValidResponseType) =>
    responseJson && !responseJson.errors;

  apiCall = async (apiData: ApiCallInterface) => {
    let token = await getStorageData("token");
    const { contentType, method, endPoint, body } = apiData;
    const header = {
      "Content-Type": contentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  getPriorityApi = async (dates: DateRange) => {
    this.setState({ loader: true });
    const { firstDay, lastDay } = dates;
    let isResponseReceived = false;
    const timeoutId = setTimeout(() => {
      if (!isResponseReceived) {
        this.setState({ priorities: this.setInitialPriorities() });
      }
    }, 30000);

    this.getPriorityApiCallId = await this.apiCall({
      contentType: config.appJsonContentType,
      method: config.getMethod,
      endPoint:
        config.getPriorityAPIEndPoint +
        `?from_date=${firstDay}&to_date=${lastDay}`,
    });
    this.apiCallTimeoutId = timeoutId;
  };

  getDateRangeFromMonth(date: Date) {
    const today = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();
    let firstDay;
    if (year === today.getFullYear() && month === today.getMonth()) {
      firstDay = addDays(today, 1).toISOString();
    } else {
      firstDay = new Date(year, month, 1).toISOString();
    }
    const lastDay = new Date(year, month + 1, 0).toISOString();
    return { firstDay, lastDay };
  }

  setInitialPriorities = (): Priorities => {
    const priorities: Priorities = {};
    const indices = Array.from({ length: 31 }, (_, itemData) => itemData);
    let priorityLimit = 0;
    const today = new Date();
    const tomorrow = addDays(today, 1);

    indices.forEach((value) => {
      const date = addDays(tomorrow, value - 1);
      const dateString = format(date, "yyyy-MM-dd");
      const isWeekend = getDay(date) === 0 || getDay(date) === 6;
      if (!isWeekend) {
        priorityLimit += 1;
        if (priorityLimit > 10) {
          priorities[dateString] = "Standard";
        } else {
          priorities[dateString] = "Priority";
        }
      } else {
        priorities[dateString] = "Super Priority";
      }
    });
    this.setState({ loader: false });
    return priorities;
  };

  isPriorityMethodArray(obj: any): obj is PriorityMethodArray {
    return (
      Array.isArray(obj) &&
      obj.every(
        (item) =>
          typeof item === "object" &&
          typeof item.priority === "number" &&
          typeof item.date === "string" &&
          item !== null
      )
    );
  }

  handlePrioritySet(responseArray: PriorityMethodArray) {
    responseArray.forEach((response) => {
      const priorityCount: number = response.priority || 0;
      const dateString: any =
        format(parseISO(response.date), "yyyy-MM-dd") || "";
      let priorityString: PriorityType;
      switch (priorityCount) {
        case 0:
          priorityString = "Standard";
          break;
        case 1:
          priorityString = "Priority";
          break;
        case 2:
          priorityString = "Super Priority";
          break;
        case 3:
          priorityString = "Not Available";
          break;
        default:
          priorityString = "Standard";
          break;
      }
      if (dateString.trim() !== "") {
        this.setState((prevState) => ({
          priorities: {
            ...prevState.priorities,
            [dateString]: priorityString,
          },
        }));
      }
    });
    this.setState({ loader: false });
  }

  validationSchema = yup.object().shape({
    fullName: yup.string().required(),
    email: yup
      .string()
      .email("Please enter a valid email address.")
      .required("Enter your client's email address."),
    countryCode: yup.string(),
    mobileNumber: yup.string().required(),
    serviceType: yup.string().required(),
    notarisationMethod: yup.string().required(),
    fees: yup.number().required(),
    platformFees: yup.boolean(),
    startTime: yup.date().required(),
    endTime: yup.date().required(),
    videoCall: yup.boolean().when("notarisationMethod", {
      is: (method) => method === "REN" || method === "REIN",
      then: yup
        .boolean()
        .oneOf(
          [true],
          "Video call is required for REN and REIN notarisation methods"
        ),
      otherwise: yup.boolean(),
    }),
    notes: yup.string(),
  });

  findHelperTextColor = (isError: boolean | undefined) =>
    isError ? "red" : "#011342";

  findDateValue = () => {
    if (this.state.selectedDate !== null && this.state.selectedSession !== "")
      return (
        format(this.state.selectedDate, "dd/MM/yyyy") +
        "-" +
        this.state.selectedSession
      );
    else if (this.state.selectedSession !== "")
      return this.state.selectedSession;
    else if (this.state.selectedDate !== null)
      return format(this.state.selectedDate, "dd/MM/yyyy");
    else return "";
  };

  findTimeValue = (time: Date | null) => {
    return time !== null ? format(time, "hh:mm A") : "";
  };

  findVideoCallCheckboxColor = (isError: boolean) =>
    isError ? "red" : "#64748B";

  findVideoCallColor = (isError: boolean) => (isError ? "red" : "#011342");

  isVideoCallAsteriskShown = (method: string) =>
    method.includes("REN") || method.includes("REIN");

  calendarOpen = () => {
    this.setState({ calendarOpen: true, isSelectedDate: false });
  };

  save = () => {
    this.setState({
      calendarOpen: false,
      selectedDate: this.state.tempSelectedDate,
      selectedSession: this.state.tempSelectedSession,
    });
  };

  cancel = () => {
    this.setState({
      calendarOpen: false,
      tempSelectedDate: this.state.selectedDate,
      currentMonth: new Date(),
      tempSelectedSession: this.state.selectedSession,
    });
  };

  setSession = (session: string) => this.setState({ tempSelectedSession: session });

  leftArrow = () => {
    const {
      currentMonth,
      priorities,
    }: { currentMonth: Date; priorities: { [key: string]: any } } = this.state;
    const previousMonth: Date = subMonths(currentMonth, 1);
    const month: string = format(previousMonth, "MM");
    const year: string = format(previousMonth, "yyyy");
    const daysInMonth: number = new Date(
      parseInt(year),
      parseInt(month),
      0
    ).getDate();
    const currentDate: Date = new Date();
    const startOfCurrentMonth: Date = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    if (previousMonth < startOfCurrentMonth) {
      this.setState({ currentMonth: previousMonth });
      return;
    }
    const dayFoundInPriorities = Array.from(
      { length: daysInMonth },
      (_, day) => {
        const dayOfMonth: string = `${year}-${month}-${(day + 1)
          .toString()
          .padStart(2, "0")}`;
        return priorities[dayOfMonth];
      }
    ).some(Boolean);
    if (dayFoundInPriorities) {
      this.setState({ currentMonth: previousMonth });
      return;
    }
    this.setState({ currentMonth: previousMonth });
    this.getPriorityApi(this.getDateRangeFromMonth(previousMonth));
  };

  rightArrow = () => {
    const {
      currentMonth,
      priorities,
    }: { currentMonth: Date; priorities: { [key: string]: any } } = this.state;
    const nextMonth: Date = addMonths(currentMonth, 1);
    const month: string = format(nextMonth, "MM");
    const year: string = format(nextMonth, "yyyy");
    const daysInMonth: number = new Date(
      parseInt(year),
      parseInt(month),
      0
    ).getDate();
    const currentDate: Date = new Date();
    const startOfCurrentMonth: Date = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    if (nextMonth < startOfCurrentMonth) {
      this.setState({ currentMonth: nextMonth });
      return;
    }
    const dayFoundInPriorities = Array.from(
      { length: daysInMonth },
      (_, day) => {
        const dayOfMonth: string = `${year}-${month}-${(day + 1)
          .toString()
          .padStart(2, "0")}`;
        return priorities[dayOfMonth];
      }
    ).some(Boolean);
    if (dayFoundInPriorities) {
      this.setState({ currentMonth: nextMonth });
      return;
    }
    this.setState({ currentMonth: nextMonth });
    this.getPriorityApi(this.getDateRangeFromMonth(nextMonth));
  };

  handleDateClick = (date: Date | null, priorityName: string) => {
    this.setState({
      tempSelectedDate: date,
      isSelectedDate: false,
      priorityName: priorityName,
    });
  };

  getEmailError = (error: string | undefined) =>
    error ? error : "Enter your client's email address.";

  checkDateError = () => {
    this.setState({
      isSelectedDate:
        this.state.selectedDate === null || this.state.selectedSession === "",
    });
  };
  // Customizable Area End
}
