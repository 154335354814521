export const allRequest = require("../assets/All_Orders.png");
export const progressRequest = require("../assets/In_Progress.png");
export const completeRequest = require("../assets/Completed_Orders.png");
export const bellIcon = require("../assets/bellICon.png");
export const groupImg = require('../assets/grouppage.png');
export const logoImg = require('../assets/logo.png');
export const logoBlueImg = require('../assets/logoBlue.png');
export const localProfile = require("../assets/acount.png");
export const saveImage = require("../assets/095be4a7d59419f3e29cdf3ae2cd8135.png");
export const cancelImage = require("../assets/5fa4e37863a6766904897d4629041c86.png");
export const service = require("../assets/service.png");
export const outgoing = require("../assets/outgoing.png");
export const failureImage = require("../assets/failure.png");

