Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.getProfileAPiEndPoint = "bx_block_profile/profiles/get_profile";
exports.getProfileAPiEndPointType = "GET";
exports.bookNow = "Book Now";
exports.labelTitleText = "Notary Services";
exports.inviteClient = "Invite Client";
exports.serviceDataApiEndpoint="bx_block_landing_page/admin_landing_pages/get_services";
exports.serviceApiEndpointApiType="GET";

exports.getProfileContentType = "application/json";
exports.getProfileApiEndPoint = "bx_block_profile/profiles/get_profile";
exports.getProfileApiMethodType = "GET";

exports.allServicesApiEndpoint =
  "bx_block_landing_page/admin_landing_pages/get_services";
exports.allServicesApiMethodType = "GET";
exports.allservicesApiContentType = "application/json";

exports.getAllJurisdictionsApiContentType = "application/json";
exports.getAllJurisdictionsApiMethod = "GET";
exports.getAllJuridictionsAPIEndPoint =
  "bx_block_menu_ordering/notary_requests/all_jurisdictions";

  exports.getPopularNotaryApiContentType = "application/json";
  exports.getPopularNotaryApiMethod = "GET";
  exports.getPopularNotaryAPIEndPoint =
    "bx_block_catalogue/catalogues/top_notaries"; 

    exports.getPopularServiceseApiContentType = "application/json";
    exports.getPopularServiceseApiMethod = "GET";
    exports.getPopularServiceseAPIEndPoint =
      "bx_block_catalogue/catalogues/top_services"; 


      exports.getjuridictionApiMethodendpoint ='bx_block_catalogue/catalogues/search'
      exports.getjuridictionApiMethodType ='GET'
      exports.getjuridictionApiMethodContentType ='application/json'
      
      exports.getservicedataApiEndpoint ='bx_block_landing_page/admin_landing_pages/get_services'
      exports.getservicedataApiMethodeType ='GET'
      exports.getservicedataApiContentType ="application/json"

  exports.getpopularNotaryApiMethodendpoint ='bx_block_catalogue/catalogues/notaries'
  exports.getpopularNotaryApiMethodType ='GET'
  exports.getpopularNotaryApiMethodContentType ='application/json'

// Customizable Area End