Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "NavigationMenu";
exports.labelBodyText = "NavigationMenu Body";

exports.btnExampleTitle = "CLICK ME";

exports.openDrawerText = "Open Drawer";
exports.userName = "Jordan Ramsay";
exports.userDesignation = "Miami Herica";
exports.logout = "Logout";

exports.jsonApiContentType = "application/json";
exports.getMenuItemsEndPoint = "navmenu/navigation_menu";
exports.getApiMethodType = "GET";

exports.getProfileContentType = "application/json";
exports.getProfileApiEndPoint = "bx_block_profile/profiles/get_profile";
exports.getProfileApiMethodType = "GET";

exports.googleCalendarClientId =
  "970345475312-klp702ahgsvvnt3eun75igej2ioq8cjj.apps.googleusercontent.com";
exports.googleCalendarScope = "https://www.googleapis.com/auth/calendar";
exports.getEventApiMethod = "GET";
exports.addEventApiMethod = "POST";
exports.googleAuthUserEndpoint = "https://www.googleapis.com/oauth2/v2/userinfo"
exports.outlookAuthUserInfoEndpoint = "https://graph.microsoft.com/v1.0/me";
exports.outlookUserProfilePicsEndpoint = "https://graph.microsoft.com/v1.0/me/photo/$value";
exports.googleEventEntPoint ="https://www.googleapis.com/calendar/v3/calendars/primary/events";
exports.getOutlookEventEndpoint = "https://graph.microsoft.com/v1.0/me/events";
exports.getOutlookMeetingEndpoint = "https://graph.microsoft.com/v1.0/me/calendarView?"
exports.renotaryEventApiEndpoint = "bx_block_cfzoomintegration92/zoom_meetings/all_meetings"
exports.getAllNotaryRequestApiEndpoint = "bx_block_menu_ordering/notary_requests?page=1&per_page=10";
exports.allServiceApiEndpoint = "bx_block_landing_page/admin_landing_pages/get_services";
// Customizable Area End