import React from "react";

// Customizable Area Start
import {
  Button,
  Grid,
  Typography,
  Card,
  CardContent,
  Divider,
  Box,
  CardMedia,
  styled,
  CircularProgress
} from "@material-ui/core";

import {
  book,
  group,
  hands,
  home,
  image001,
  image002,
  image003,
  image004,
  image005,
  image006,
  image007,
  leftArrow,
  mision,
  rightArrow,
  vector1,
  vector2,
  vector3,
  vector4,
  vector5,
  vision,
  whyus1,
  whyus2,
  whyus3,
  whyus4,
} from "./assets";
import CallMadeIcon from "@material-ui/icons/CallMade";
import Header from "../src/Header.web";
import { Footer } from "../../../components/src/Footer.web";
// Customizable Area End

import LandingPageController, { Props } from "./LandingPageController";
import { ExpandMore } from "@material-ui/icons";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";

import "slick-carousel/slick/slick-theme.css";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Accordion from "@material-ui/core/Accordion";

import AccordionDetails from "@material-ui/core/AccordionDetails";
export default class Landingpageuser extends LandingPageController {
  private sliderRef: any;
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.sliderRef = React.createRef();
    // Customizable Area End
  }
  // Customizable Area Start
  HowItWorksBox = () => {
    return (<Box
    style={{ width: "100%", height: "100%" }}
    display={"flex"}
    flexDirection={"column"}
    justifyContent={"center"}
  >
    <Typography style={webStyles.groupHeader}>
      {this.state.homeData.how_it_works?.title.toUpperCase()}
    </Typography>
    <Typography variant="h3" style={webStyles.groupSubHeader}>
     
      {this.state.homeData.how_it_works?.subtitle}
    </Typography>
    <Typography
      style={{
        ...webStyles.groupBody,
        fontWeight: 400,
        fontSize: "18px",
        lineHeight: "26px",
        paddingBottom: 30,
      }}
    >
      {this.state.homeData.how_it_works?.description}
     
    </Typography>
  </Box>)
  }

  VisionMissionImageGrid = () => {
    return <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
     {this.state.loading ? <CircularProgress />:(

    <img
      src={this.state.homeData.vision_mission?.vision_mission_image?.url}
      alt="notary"
      width={"100%"}
      style={{
        borderRadius: "12px",
      }}
    />
     )}
  </Grid>
  }

  VisionBoxContent = () => {
    return <>
    <img
                      src={this.state.homeData.vision_mission?.vision?.icon?.url}
                      alt="notary"
                      style={{ paddingRight: 12,width:'42px',height:'40px' }}
                    />
                    <Box>
                      <Typography
                        style={{ ...webStyles.groupHeader, color: "#012275" }}
                      >
                        {this.state.homeData.vision_mission?.vision?.title.toUpperCase()}
                      </Typography>
                      <Typography style={webStyles.groupBody}>
                      {this.state.homeData.vision_mission?.vision?.description}

                      </Typography>
                    </Box>
                    </>
  }

  MissionBoxContent = () => {
    return <>
    <img
                      src={this.state.homeData.vision_mission?.mission?.icon?.url}
                      alt="notary"
                      style={{ paddingRight: 12,width:'42px',height:'40px'}}
                    />
                    <Box>
                      <Typography
                        style={{ ...webStyles.groupHeader, color: "#012275" }}
                      >
                      {this.state.homeData.vision_mission?.mission?.title.toUpperCase()}
                      </Typography>
                      <Typography style={webStyles.groupBody}>
                      {this.state.homeData.vision_mission?.mission?.description}

                      </Typography>
                    </Box>
                    </>
  }

  SmallVisionMissionBox = () => {
    return <Box
    style={{ width: "100%", height: "100%" }}
    display={"flex"}
    flexDirection={"column"}
    justifyContent={"center"}
  >
    <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      paddingBottom: 20,
                    }}
                  >
                    {this.VisionBoxContent()}
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-start",
                    }}
                  >
                    {this.MissionBoxContent()}
                  </Box>
    </Box>
  }

  LargeVisionMissionBox = () => {
    return <Box
                  style={{ width: "100%", height: "100%" }}
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"center"}
                >
    <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      paddingBottom: 20,
                      marginLeft: "5%",
                    }}
                  >
                    {this.VisionBoxContent()}
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      marginLeft: "5%",
                    }}
                  >
                    {this.MissionBoxContent()}
                  </Box>
</Box>
  }

  MobileHowItWorksContentGridBox = () => {
    return <MobileHowItWorksContentGrid
    item
    xs={12}
    sm={12}
    md={6}
    lg={6}
    xl={6}
    style={{ paddingTop: "24px" }}
  >
    {this.SmallVisionMissionBox()}
  </MobileHowItWorksContentGrid>
  }

  

 
  truncateText(text: any, maxLength: number) {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  }
  capitalizeFirstLetter=(word:any)=> {
    if (!word) return '';
    return word.charAt(0).toUpperCase() + word.slice(1);
  }
  capitalizeFirstLetterOfEachWord=(text: string)=> {
    if (!text) return ''; // Check if text is undefined or null
    return text
      .split(' ')
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }
  capitaleFirstLetterfingurtitle=(fingurtitle: string)=>{
    if (!fingurtitle) return ''; 
    return fingurtitle
      .split(' ')
      .map((title: string) => title.charAt(0).toUpperCase() + title.slice(1).toLowerCase())
      .join(' ');

  }
  captilFirstWhychoosSubTitle=(WhychoosSubTitle: string)=>{
    if (!WhychoosSubTitle) return ''; 
    return WhychoosSubTitle
      .split(' ')
      .map((choosSubTitle: string) => choosSubTitle.charAt(0).toUpperCase() + choosSubTitle.slice(1).toLowerCase())
      .join(' ');

  }

  capitalFirstLetterStepsubtitle=(Stepsubtitle: string)=>{
    if (!Stepsubtitle) return '';
    return Stepsubtitle
      .split(' ')
      .map((StepTitle: string) => StepTitle.charAt(0).toUpperCase() + StepTitle.slice(1).toLowerCase())
      .join(' ');

  }

  capitalFirstLetterStepnamber=(Stepnamber: string)=>{
    if (!Stepnamber) return '';
    return Stepnamber
      .split(' ')
      .map((Stepnumber: string) => Stepnumber.charAt(0).toUpperCase() + Stepnumber.slice(1).toLowerCase())
      .join(' ');

  }
  captilFirstStorySubTitle=(StorySubTitle: string)=>{
    if (!StorySubTitle) return ''; 
    return StorySubTitle
      .split(' ')
      .map((StorySubTitles: string) => StorySubTitles.charAt(0).toUpperCase() + StorySubTitles.slice(1).toLowerCase())
      .join(' ');

  }
  // Customizable Area End
  render() {
    const CardStyling = styled(Box)({
      "& .MuiCardMedia-media": {
        width: "none",
      },
      "& .card-style": {
        width: "300px",
      },
    });

    const Sliderstyling = styled(Box)({
      "& .slick-prev, .slick-next": {
        display: "none !important",
      },
    });

    const ResDesign = styled(Box)({
      "& .slick-buttons": {
        display: "flex",
        justifyContent: "center",
        alignItems: "end",
        marginLeft: "8%",
        "@media (min-width: 1110px) and (max-width:1250px)": {
          marginLeft: "2%",
        },
        "@media (min-width: 1030px) and (max-width:1109px)": {
          marginLeft: "-2%",
        },
        "@media (min-width: 950px) and (max-width:1029px)": {
          marginLeft: "-5%",
        },
      },
    });
    const {expanded} = this.state;
    const faqData = this.state.faqData || []
    const servicesData =this.state.serviceData ||[]
    const title = this.state.faqTitle.faq_title || '';
    const firstTwoWords = title.split(' ').slice(0, 2);
    const transformedFirstWord = firstTwoWords.length > 0 ? this.capitalizeFirstLetter(firstTwoWords[0]) : '';
    const remainingWords = firstTwoWords.slice(1).join(' ');
    const serviceText  = this.state?.homeData?.service_sub_title || ''
     const serviceTextSubtitle = this.capitalizeFirstLetterOfEachWord(serviceText); 
     const figuresubtitle   = this.capitaleFirstLetterfingurtitle(this.state.homeData.notary_services_fingertips?.subtitle)
     const stepsubtitle  = this.capitalFirstLetterStepsubtitle(this.state.homeData.step?.subtitle)
     const whychoossubtitle = this.captilFirstWhychoosSubTitle(this.state.homeData.why_choose_us?.subtitle)
    return (
      // Customizable Area Start
      // Required for all blocks
      <>
      <div ref={this.state.topRef}></div>
      <Header navigation={this.props.navigation} id={""} />
        <Box m={"142px 5% 30px"}>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} sm={12} md={7} lg={6} xl={6}>
              <Box  width={"100%"}>
                <Typography style={webStyles.typo1}>
                  {this.state.homeData.notary_services_fingertips?.title.toUpperCase()}
                </Typography>
                <Typography style={webStyles.typo2}>
                {figuresubtitle}
                </Typography>
                <Typography style={webStyles.typo3}>
                {this.state.homeData.notary_services_fingertips?.description}

                </Typography>
                <Button
                  variant="contained"
                  style={{
                    ...webStyles.signup,
                    textTransform:"none"
                    

                  }}
                >
                  Get Started
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={6} xl={6}>
              {this.state.loading ? <CircularProgress />:(
              <Box display={"flex"} justifyContent={"center"}>
                <img
                  src={this.state.homeData.notary_services_fingertips?.image?.url}
                  alt="notary"
                  width={"100%"}
                  style={{
                    border: " 1px solid #C5C6C8",
                    borderRadius: 8,
                    maxHeight: "686px",
                  }}
                />
              </Box>
              )}
            </Grid>
          </Grid>
        </Box>
        <Box m={"30px 6% 40px"} className="slider-container">
          <Slider
            className="slider variable-width"
            variableWidth={true}
            dots={false}
            infinite={true}
            slidesToShow={1}
            slidesToScroll={1}
            autoplay={true}
            speed={3000}
            autoplaySpeed={3000}
            cssEase="linear"
          >{this.state.logoData.map((logo)=>(
            <Box style={{width: "280px"}}>
              <Box style={webStyles.vector}>
                <img src= {logo?.attributes?.logo_image?.url}
                 alt="notary" width="48px" height={"48px"} />
                <Typography style={webStyles.vectorContent}>
                 
                  {logo?.attributes?.logo_title}
                </Typography>
              </Box>
            </Box>
            ))}
           
          </Slider>
        </Box>
        <Box m={"40px 5% 70px"} pt={"40px"} pb={"40px"}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"end"}
          >
            <Box>
              <Typography
                style={{
                  color: "#0131A8",
                  fontSize: "20px",
                  fontWeight: 700,
                  fontFamily: "INTER",
                }}
              >
                {this.state.homeData?.service_title?.toUpperCase()}
              </Typography>
              <Typography
                variant="h3"
                style={{
                  color: "#011342",
                  fontSize: "36px",
                  fontWeight: 700,
                  fontFamily: "INTER",
                }}
              >
                {serviceTextSubtitle}
              </Typography>
            </Box>
            <Box
              display={"flex"}
              justifyContent="space-between"
              width={"104px"}
            >
              <Button
                data-test-id="prevBtn"
                onClick={() => this.sliderRef?.current?.slickPrev() || ""}
              >
                <img src={leftArrow} alt="notary" width={"44px"} />
              </Button>

              <Button
                data-test-id="nextBtn"
                onClick={() => this.sliderRef?.current?.slickNext() || ""}
              >
                <img src={rightArrow} alt="notary" width={"44px"} />
              </Button>
            </Box>
          </Box>
          <Box mt={"33px"}>
            <Slider ref={this.sliderRef} slidesToShow={1} variableWidth={true}>
              {servicesData.map((item: { imageUrl: React.Key | null | undefined; attributes: { service_icon: { url: string | undefined; }; service_name: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; service_description: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; }; }, index: number) => (
                <Box
                  key={index}
                  maxWidth={"303px"}
                  maxHeight={"240px"}
                  padding={"16px 6px"}
                  style={{ columnGap: "24px" }}
                >
                  <Card>
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      alignItems={"center"}
                      height={"240px"}
                      width={"302px"}
                    >
                      <CardMedia
                        style={{
                          width: "44px",
                          height: "44px",
                          paddingTop: "40px",
                        }}
                        component="img"
                        image={item?.attributes?.service_icon?.url}
                        alt={`Image ${index + 1}`}
                      />
                      <CardContent>
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: 600,
                            textAlign: "center",
                            fontFamily:'Inter',
                          }}
                          gutterBottom
                          variant="h5"
                          component="div"
                        >
                          {item?.attributes?.service_name}
                        </Typography>
                        <Typography
                          style={{
                            fontSize: "12px",
                            fontWeight: 500,
                            textAlign: "center",
                            fontFamily:'Inter',
                          }}
                          variant="body2"
                        >
                          {this.truncateText(item?.attributes?.service_description, 180)}

                        </Typography>
                      </CardContent>
                    </Box>
                  </Card>
                </Box>
              ))}
            </Slider>
          </Box>
        </Box>
        <Box style={{ background: "#011342" }} p={"72px 5%"}>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              paddingBottom: "50px",
            }}
          >
            <Typography style={webStyles.cardHeader}>{this.state.homeData?.step?.title?.toUpperCase()}</Typography>
            <Typography variant="h3" style={webStyles.cardSubHeader}>
            {stepsubtitle}     
               </Typography>
          </Box>
          <DesktopHowItWorksStepsBox>
  <Grid container spacing={2} justifyContent="space-around">
    {this.state.stepsData && this.state.stepsData.slice(1).map((steps, index) => (
      <Grid
        key={index}
        item
        xs={12}
        sm={6}
        md={6}
        lg={3}
        xl={3}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Card style={{ ...webStyles.cards, maxWidth: "300px" }}>
          <CardContent>
            <Typography style={webStyles.steps1}>
              {steps?.number.toUpperCase()}
            </Typography>
            <Typography style={webStyles.steps2}>
              {this.capitalFirstLetterStepnamber(steps?.name)}
            </Typography>
            <Typography style={webStyles.steps3}>
              {steps?.description}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    ))}
  </Grid>
         </DesktopHowItWorksStepsBox>

         <TabletHowItWorksStepsBox>
  <Box display={"flex"} style={{ columnGap: "4px" }}>
    {this.state.stepsData && this.state.stepsData.slice(0, 2).map((steps, index) => (
      <Card key={index} style={{ ...webStyles.cards, maxWidth: "300px" }}>
        <CardContent>
          <Typography style={webStyles.steps1}>
            {steps?.number}
          </Typography>
          <Typography style={webStyles.steps2}>
            {steps?.name}
          </Typography>
          <Typography style={webStyles.steps3}>
            {steps?.description}
          </Typography>
        </CardContent>
      </Card>
    ))}
  </Box>
  <Box display={"flex"} style={{ columnGap: "4px" }}>
    {this.state.stepsData && this.state.stepsData.slice(2, 4).map((steps, index) => (
      <Card key={index} style={{ ...webStyles.cards, maxWidth: "300px" }}>
        <CardContent>
          <Typography style={webStyles.steps1}>
            {steps?.number}
          </Typography>
          <Typography style={webStyles.steps2}>
            {steps?.name}
          </Typography>
          <Typography style={webStyles.steps3}>
            {steps?.description}
          </Typography>
        </CardContent>
      </Card>
    ))}
  </Box>
</TabletHowItWorksStepsBox>

        </Box>
        <Box mt={"70px"}>
          <Box mx={"6%"} mt={"80px"}>
            <Grid container justifyContent="space-evenly">
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              {this.state.loading ? <CircularProgress />:(
                <StyledImage
                   
                  src={this.state.homeData.how_it_works?.image?.url}
                  alt="notary"
                  style={{ borderRadius: "12px" }}
                />
              )}
              </Grid>
              <MobileHowItWorksContentGrid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                style={{ paddingTop: "24px" }}
              >
                {this.HowItWorksBox()}
              </MobileHowItWorksContentGrid>
              <DesktopHowITWorksContentGrid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                style={{ paddingLeft: "4%" }}
              >
                {this.HowItWorksBox()}
              </DesktopHowITWorksContentGrid>
            </Grid>
          </Box>
          <DesktopVisionMissionBox mx={"10%"} mt={"48px"}>
            <Grid container justifyContent="space-between">
              {this.VisionMissionImageGrid()}
              <MobileHowItWorksContentGrid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                style={{ paddingTop: "24px" }}
              >
                {this.LargeVisionMissionBox()}
              </MobileHowItWorksContentGrid>
              <DesktopHowITWorksContentGrid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                style={{ paddingLeft: "8%" }}
              >
                {this.LargeVisionMissionBox()}
              </DesktopHowITWorksContentGrid>
            </Grid>
          </DesktopVisionMissionBox>
          <TabletVisionMissionBox mx={"6%"} mt={"48px"}>
            <Grid container justifyContent="space-between">
              {this.VisionMissionImageGrid()}
              {this.MobileHowItWorksContentGridBox()}
              <DesktopHowITWorksContentGrid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                style={{ paddingLeft: "4%" }}
              >
                {this.SmallVisionMissionBox()}
              </DesktopHowITWorksContentGrid>
            </Grid>
          </TabletVisionMissionBox>
          <MobileVisionMissionBox mx={"6%"} mt={"48px"}>
            <Grid justifyContent="space-between" container>
              {this.VisionMissionImageGrid()}
              {this.MobileHowItWorksContentGridBox()}
              <DesktopHowITWorksContentGrid
                item
                style={{ paddingLeft: "8%" }}
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
              >
                {this.LargeVisionMissionBox()}
              </DesktopHowITWorksContentGrid>
            </Grid>
          </MobileVisionMissionBox>
        </Box>
        <Box mt={"80px"}>
          <Grid container style={{ background: "#011342" }}>
            <Grid
              item
              sm={12}
              md={6}
              lg={6}
              xl={6}
              style={{
                ...webStyles.handsMain,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "48px 0",
              }}
            >
              <Box width="80%">
                <Typography style={webStyles.handsTypo1}>
                  {this.state.homeData.story?.title.toUpperCase()}
                </Typography>
                <Typography
                  style={{
                    ...webStyles.handsTypo2,
                    fontSize: "48px",
                    lineHeight: "56px",
                    letterSpacing: "-1.5%",
                  }}
                >
               {this.captilFirstStorySubTitle(this.state.homeData.story?.subtitle)}

                </Typography>
                <Typography style={webStyles.handsTypo3}>
                {this.state.homeData.story?.description}

                </Typography>
                <Grid container>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Box
                      style={{
                        borderLeft: "1px solid blue",
                        paddingLeft: 10,
                        marginBottom: 15,
                      }}
                    >
                      <Typography style={{ ...webStyles.handsTypo2 }}>
                        10x
                      </Typography>
                      <Typography style={webStyles.handsTypo33}>
                        Streamlining of process
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Box
                      style={{
                        borderLeft: "1px solid blue",
                        paddingLeft: 10,
                        marginBottom: 15,
                      }}
                    >
                      <Typography
                        style={{ ...webStyles.handsTypo2, fontSize: 36 }}
                      >
                        300%
                      </Typography>
                      <Typography style={webStyles.handsTypo33}>
                        Boost in document security
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Box
                      style={{
                        borderLeft: "1px solid blue",
                        paddingLeft: 10,
                        marginBottom: 15,
                      }}
                    >
                      <Typography
                        style={{ ...webStyles.handsTypo2, fontSize: 36 }}
                      >
                        5k+
                      </Typography>
                      <Typography style={webStyles.handsTypo33}>
                        Satisfied clients
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Box
                      style={{
                        borderLeft: "1px solid blue",
                        paddingLeft: 10,
                        marginBottom: 15,
                      }}
                    >
                      <Typography
                        style={{ ...webStyles.handsTypo2, fontSize: 36 }}
                      >
                        100+
                      </Typography>
                      <Typography style={webStyles.handsTypo33}>
                        Top-rated reviews
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <DesktopCustomerSuccessStoriesGrid
              item
              sm={12}
              md={6}
              lg={6}
              xl={6}
            >
            {this.state.loading ? <CircularProgress />:(

              <img
               src={this.state.homeData.story?.image?.url}
                data-testID="customerSuccessStoryImage"
              alt="notary" width="100%" height={"100%"}/>
              )}
            </DesktopCustomerSuccessStoriesGrid>
            <TabletCustomerSuccessStoriesGrid
              item
              xs={12}
              sm={12}
              md={6}
              style={{ padding: "48px 5% 48px 0" }}
            >
              <Box
                height={"100%"}
                width={"100%"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <img src={hands} alt="notary" width="100%" />
              </Box>
            </TabletCustomerSuccessStoriesGrid>
            <MobileHowItWorksContentGrid
              item
              xs={12}
              sm={12}
              md={6}
              style={{ padding: "0 10% 48px" }}
            >
              <Box
                height={"100%"}
                width={"100%"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <img src={hands} alt="notary" width="100%" />
              </Box>
            </MobileHowItWorksContentGrid>
          </Grid>
        </Box>
        <Box mt={"80px"}>
          <Grid container justifyContent="center" alignItems="flex-start">
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={6}
              xl={6}
              style={{ ...webStyles.main, padding: "0 5%" }}
            >
              <MobileWhyChooseUsBox 
              pb={"32px"}
              width={"100%"} 
              >
                <Typography
                  style={{ ...webStyles.typo1, textTransform: "uppercase" }}
                >
                  {this.state.homeData.why_choose_us?.title.toUpperCase()}
                </Typography>
                <Typography style={{ ...webStyles.typo21, fontSize: "36px" }}>
                {whychoossubtitle}
                </Typography>
                
              </MobileWhyChooseUsBox>
              <DesktopWhychooseUsBox width={"67%"}>
                <Typography
                  style={{ ...webStyles.typo1, textTransform: "uppercase" }}
                >
                  {this.state.homeData.why_choose_us?.title.toUpperCase()}
                </Typography>
                <Typography style={{ ...webStyles.typo21, fontSize: "36px" }}>
                  {whychoossubtitle}

                </Typography>
              
              </DesktopWhychooseUsBox>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={6}
              xl={6}
              style={{ ...webStyles.main, padding: "0 5%" }}
            >
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "32px",
                }}
              >
                {this.state.whyChoosData && this.state.whyChoosData.map((whyUsPoint, index) => (
        <Box
          key={index}
          style={{
            alignItems: "flex-start",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <img
            style={{ paddingRight: 12,width:'42px',height:'40px' }}
            src={whyUsPoint.icon?.url}
            alt="notary"
          />
          <Box>
            <Typography
              style={{
                textTransform: "uppercase",
                ...webStyles.groupHeader,
                color: "#012275",
              }}
            >
              {whyUsPoint?.name}
            </Typography>
            <Typography style={{ ...webStyles.groupBody }}>
              {whyUsPoint?.description}
            </Typography>
          </Box>
        </Box>
      ))}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box mx={"8%"} mt={"120px"} py={"40px"}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"flex-start"}
          >
            <Typography
              style={{
                ...webStyles.typo1,
                marginRight: "16px",
                marginBottom: 0,
                fontSize: "2.5rem",
                color: "#040919",
              }}
            > 
               {`${transformedFirstWord} ${remainingWords}`}   
                           <br />
               {this.state.faqTitle.faq_title?.split(' ').slice(2).join(' ')}

            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "end",
                paddingTop: "8px",
              }}
            >
              <Button target="_blank" href="/Faq">
              <CallMadeIcon style={{ color: "#475569" }} 
                      // onClick={this.openFaqPage}

              />
              </Button>
            </Box>
          </Box>

          <Box paddingTop={"40px"}>
            
    {faqData.slice(0, 5).map((accoItem,index) => (
      <Accordion
      key={index}
      style={{ boxShadow: "none", borderBottom: "1px solid #CBD5E1" }}
      expanded={expanded === index}
      data-test-id="handleAccolist"
       onChange={this.handleAcco(index)}
    >
      <AccordionSummary
        expandIcon={
          <ExpandMore style={{color: "#011342" ,fontWeight: 600 }} />
        }
        style={{color: "#011342",
        fontWeight:600,
         padding:"unset"}}
         id="panel1-header"
         aria-controls="panel1-content"

      >
        {accoItem.question}
      </AccordionSummary>
      <AccordionDetails
        style={{
          font: "INTER",
          fontWeight: 400,
          color: "#011342",
          fontSize: "18px",
        }}
      >
        {accoItem.answer}
      </AccordionDetails>
    </Accordion>
            ))}
          </Box>
        </Box>
        <Footer  testID="FooteText" isChecked={false}/>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const TabletHowItWorksStepsBox = styled(Box)({
  "@media (min-width: 1152px) or (max-width: 499px)": {
    display: "none"
  },
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  rowGap: "4px",
})

const DesktopHowItWorksStepsBox = styled(Box)({
  "@media (max-width: 1151px) and (min-width: 500px)": {
    display: "none"
  }
})

const MobileWhyChooseUsBox = styled(Box)({
  "@media (min-width: 1040px)": {
    display: "none"
  }
})

const DesktopWhychooseUsBox = styled(Box)({
  "@media (max-width: 1040px)": {
    display: "none"
  }
})

const DesktopCustomerSuccessStoriesGrid = styled(Grid)({
  "@media (max-width: 1231px)": {
    display: "none"
  }
})

const TabletCustomerSuccessStoriesGrid = styled(Grid)({
  "@media (min-width: 1232px) or (max-width: 959px)": {
    display: "none"
  }
})

const StyledImage = styled("img")({
  "@media (max-width: 1231px)": {
    width: "100%"
  },
  "@media (min-width: 1232px)": {
    width: "90%"
  }
})

const  MobileHowItWorksContentGrid = styled(Grid) ({
  "@media (min-width: 960px)": {
    display: "none",
  },
})

const DesktopHowITWorksContentGrid = styled(Grid)({
  "@media (max-width: 959px)": {
    display: "none",
  }
})

const MobileVisionMissionBox = styled(Box)({
  "@media (min-width: 884px)": {
    display: "none"
  }
})

const DesktopVisionMissionBox = styled(Box)({
"@media (max-width: 1231px)": {
  display: "none"
}
})

const TabletVisionMissionBox = styled(Box)({
  "@media (max-width: 883px) or (min-width: 1232px)": {
    display: "none"
  }
})

const webStyles = {
  main: {
    textAlign: "left" as const,
  },
  typo1: {
    color: "#0131A8",
    fontWeight: 700,
    fontSize: "20px",
    marginBottom: 20,
    fontFamily: "INTER",
    lineHeight: 1.2, 
  },
  typo2: {
    color: "#011342",
    letterSpacing: -1,
    lineHeight: 1.2,
    marginBottom: 20,
    fontWeight: 700,
    fontSize: "48px",
    fontFamily: "INTER",
    
  },
  typo3: {
    color: "#011342",
    fontWeight: 400,
    fontSize: "20px",
    marginBottom: 20,
    fontFamily: "INTER",
    lineHeight: 1.2, 
  },
  vector: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    columnGap: "8px"
  },
  vectorContent: {
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "32px",
    letterSpacing: "-0.5",
    color: "#011342",
  },
  signup: {
    backgroundColor: "#012275",
    color: "#FFFFFF",
    fontWeight: 700,
    fontSize: 16,
    padding: "12px 28px",
    texttransform: "capitalize",
    fontFamily: "INTER",

  },
  cardHeader: {
    color: "#E2E8F0",
    fontSize: "20",
    fontWeight: 700,
    textAlign: "center" as const,
    fontFamily: "INTER",
  },
  cardSubHeader: {
    color: "#FFFFFF",
    fontSize: "30",
    fontWeight: 600,
    textAlign: "center" as const,
    fontFamily: "INTER",
  },
  steps1: {
    color: "#FFFFFF",
    fontSize: "14",
    fontWeight: 600,
    textAlign: "center" as const,
    fontFamily: "INTER",
  },
  steps2: {
    color: "#FFFFFF",
    fontSize: "14",
    fontWeight: 600,
    textAlign: "center" as const,
    padding: "15px 0px",
    fontFamily: "INTER",
  },
  steps3: {
    color: "#FFFFFF",
    fontSize: "12",
    textAlign: "center" as const,
    fontFamily: "INTER",
  },
  cards: {
    background: "#000A34",
    padding: "25px 0px",
  },
  cardscopy: {
    margin: 5,
    background: "#fff",
    width: 245,
    height: 190,
    padding: "25px 20px",
  },
  groupHeader: {
    color: "#0131A8",
    fontSize: "20",
    fontWeight: 700,
    lineheight: "28px",
    textAlign: "left" as const,
    fontFamily: "INTER",
  },
  groupSubHeader: {
    color: "#011342",
    fontSize: "36px",
    fontWeight: 700,
    lineHeight: "44px",
    textAlign: "left" as const,
    paddingBottom: "24px",
    paddingTop: "8px",
    fontFamily: "INTER",
  },
  groupBody: {
    color: "#011342",
    fontSize: "14px",
    fontFamily: "INTER",
  },
  handsMain: {
    textAlign: "left" as const,
  },
  handsTypo1: {
    color: "#FFFFFF",
    fontWeight: 700,
    fontSize: 18,
    marginBottom: 20,
    fontFamily: "INTER",
  },
  handsTypo2: {
    color: "FFFFFF",
    letterSpacing: "-1%",
    lineHeight: "44px",
    marginBottom: 20,
    fontWeight: 700,
    fontSize: "36px",
    fontFamily: "INTER",
  },
  handsTypo3: {
    color: "#FFFFFF",
    fontWeight: 400,
    lineHeight: "24px",
    fontSize: "16px",
    marginBottom: 20,
    fontFamily: "INTER",
  },
  handsTypo33: {
    color: "#FFFFFF",
    lineHeight: "26px",
    fontWeight: 400,
    fontSize: "18px",
    marginBottom: 20,
    fontFamily: "INTER",
  },
  carouselItem: {
    width: "25%",
  },
  typo21: {
    color: "#011342",
    letterSpacing: -2,
    lineHeight: 1.2,
    marginBottom: 20,
    fontWeight: 650,
    fontSize: "1.875vw",
    fontFamily: "INTER",
  },
};

const FAQBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
});

const FAQTypography = styled(Typography)({
  ...webStyles.typo1,
  fontSize: 18,
  width: "70%",
  marginTop: 20,
  fontWeight: 600,
  color: "#040919",
});

const ExpandMoreBox = styled(Box)({
  width: "28%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "end",
});
// Customizable Area End
