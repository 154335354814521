import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  styled,
  Drawer,
  Divider,
  MenuItem,
  Typography,
  Paper,
  Grow,
} from "@material-ui/core";
import { logo, localProfile } from "./assets";
import { NavLink } from "react-router-dom";
import { Close } from "@material-ui/icons";
import MenuIcon from '@material-ui/icons/Menu';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
// Customizable Area End
import LandingPageController, { Props } from "./LandingPageController";

export default class Header extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  renderUsernameForMobile = () => {
    if(this.state.userName){
      return(
        <>
          <Box
            data-test-id="menu-box-mobile"
            borderRadius={"8px"}
            display={"flex"}
            height={"48px"}
            px={"8px"}
            alignItems={"center"}
            style={{
              boxShadow: "0 0 8px rgba(0, 0, 0, 0.1)",
              columnGap: "15px",
              backgroundColor: "#FFFFFF",
              maxWidth: "70%",  
            }}
            onClick = {this.handleNavigateToMyAccount}
          >
            <img
              style={{ 
                borderRadius: "50%", 
                backgroundColor: "grey",
                width: "40px",   
                height: "40px",
                minWidth: "32px" 
              }}
              src={
                this.state.userProfilePic !== null
                  ? this.state.userProfilePic
                  : localProfile
              }
              alt="profile_pic"
            />
            <Typography
              style={{
                fontWeight: 600,
                fontFamily: "Inter",
                fontSize: "1.3rem", 
                lineHeight: "1.5",
                cursor: 'pointer',
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap"
              }}
              data-testID="userName"
              variant="body2"
            >                  
              {this.state.userName}                   
            </Typography>
          </Box>
        </>
      )
    }
  }

  renderMobileDashboardButton = () =>{
    if(this.state.userName){
      return(
        <>
           <NavLink
            to="/Dashboard"
            style={webStyles.navLink}
            onClick={this.handleNavigationScroll}
          >
            <Typography 
              style={{ 
                padding: "24px 12px",                
                fontWeight: 400,
                fontSize: "16px",
                fontFamily: "INTER",
                cursor: "pointer"
              }}
            >
              Dashboard
            </Typography>
          </NavLink>
        </>
      )
    }
  }

  renderMobileLoginLogout = () => {
    if(this.state.userName){
      return(
        <>
          <Divider/>
          <NavLink
            to="/"
            style={webStyles.navLink}
            onClick={this.logOutFromNevigation}
          >
            <Typography 
              style={{ 
                padding: "24px 12px",                
                fontWeight: 400,
                fontSize: "16px",
                fontFamily: "INTER",
                cursor: "pointer"
              }}
            >
              Logout
            </Typography>
          </NavLink>
        </>
      )
    }
    else{
      return (
        <>
          <Divider />
          <NavLink
            to="/EmailAccountLoginBlock"
            activeStyle={webStyles.activeNavLink}
            style={webStyles.navLink}
            onClick={this.handleNavigationScroll}
          >
            <Typography style={{ padding: "24px 12px" }}>
              Log In
            </Typography>
          </NavLink>
          <Divider />
          <NavLink
            to="/OnboardingPageWeb"
            activeStyle={webStyles.activeNavLink}
            style={webStyles.navLink}
            onClick={this.handleNavigationScroll}
          >
            <Typography style={{ padding: "24px 12px" }}>
              Sign Up
            </Typography>
          </NavLink>
        </>
      )
    }
  }

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <Box sx={webStyles.headerbox}>
        <DesktopBox
          px={"80px"}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Box>
            <NavLink to="/">
              <img width={"158px"} height={"50px"} src={logo} alt="notary" />
            </NavLink>
          </Box>
          <Box style={webStyles.tabs}>
            <Box data-test-id="home" style={webStyles.boxes}>
              <NavLink
                data-test-id="homeNavLink1"
                to="/"
                exact
                activeStyle={webStyles.activeNavLink}
                style={webStyles.navLink}
                onClick={this.handleNavigationScroll}
              >
                Home
              </NavLink>
            </Box>
            <Box data-test-id="services" style={webStyles.boxes}>
              <NavLink
                to="/Services"
                activeStyle={webStyles.activeNavLink}
                style={webStyles.navLink}
                onClick={this.handleNavigationScroll}
              >
                Services
              </NavLink>
            </Box>
            <Box data-test-id="aboutus" style={webStyles.boxes}>
              <NavLink
                to="/Aboutus"
                activeStyle={webStyles.activeNavLink}
                style={webStyles.navLink}
                onClick={this.handleNavigationScroll}
              >
                About us
              </NavLink>
            </Box>
            <Box data-test-id="contactus" style={webStyles.boxes}>
              <NavLink
                to="/Contactus"
                activeStyle={webStyles.activeNavLink}
                style={webStyles.navLink}
                onClick={this.handleNavigationScroll}
              >
                Contact us
              </NavLink>
            </Box>
            <Box data-test-id="faq" style={webStyles.boxes}>
              <NavLink
                to="/Faq"
                activeStyle={webStyles.activeNavLink}
                style={webStyles.navLink}
                onClick={this.handleNavigationScroll}
              >
                FAQs
              </NavLink>
            </Box>
          </Box>
          <Box>
            {this.state.userName ? (
              <Typography
              ref={this.anchorRef}
              aria-controls={this.state.open ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              data-test-id="headmenutoggle"
              className="headButton"
              onClick={this.handleToggle}
            >
              <Box
                data-test-id="menu-box"
                borderRadius={"8px"}
                display={"flex"}
                height={"48px"}
                px={"8px"}
                alignItems={"center"}
                style={{
                  boxShadow: "0 0 8px rgba(0, 0, 0, 0.1)",
                  columnGap: "8px",
                  backgroundColor: "#FFFFFF",
                }}
              >
                <img
                  style={{ borderRadius: "50%", backgroundColor: "grey" }}
                  src={
                    this.state.userProfilePic !== null
                      ? this.state.userProfilePic
                      : localProfile
                  }
                  width="32px"
                  height="32px"
                  alt="profile_pic"
                />
                <Typography
                  style={{
                    fontWeight: 600,
                    fontFamily: "Inter",
                    fontSize: "14px",
                    lineHeight: "21px",
                    cursor: 'pointer'
                  }}
                  data-testID="userName"
                  variant="body2"
                >
                  
                  {this.state.userName}
                
                  <Popper open={this.state.open} anchorEl={this.anchorRef.current} role={undefined} transition disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={this.handleTClose}>
                    <MenuList autoFocusItem={this.state.open} data-test-id="menu-list-grow" id="menu-list-grow" >
                      <MenuItem onClick={this.handleSubNavigation}>Dashboard</MenuItem>
                      <MenuItem onClick={this.handleNavigateToMyAccount}>My Account</MenuItem>
                      <MenuItem onClick={this.logOutFromNevigation}>Logout</MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>

                  
                  
                </Typography>
              </Box>
              </Typography>
            ) : (
              <Box style={webStyles.loginsignup}>
                <NavLink
                  to="/EmailAccountLoginBlock"
                  onClick={this.handleNavigationScroll}
                >
                  <Button
                    data-test-id="loginBtn"
                    variant="outlined"
                    style={{ ...webStyles.login, textTransform: "none" }}
                  >
                    Log In
                  </Button>
                </NavLink>
                <NavLink
                  to="/OnboardingPageWeb"
                  onClick={this.handleNavigationScroll}
                >
                  <Button
                    data-test-id="loginBtn"
                    variant="outlined"
                    style={{ ...webStyles.signup, textTransform: "none" }}
                  >
                    Sign Up
                  </Button>
                </NavLink>
              </Box>
            )}
          </Box>
        </DesktopBox>
        <MobileBox
          px={"3.5%"}
          display={"flex"}
          justifyContent="space-between"
          alignItems="center"
        >
          <NavLink to="/">
            <img width={"158px"} height={"50px"} src={logo} alt="notary" />
          </NavLink>
          <Button data-test-id="toggleButton" onClick={this.toggleDrawer(true)}>
            <MenuIcon style={{ width: "50px", height: "56px" }} />
          </Button>
          <StyledDrawer
            data-test-id="togglebutton"
            open={this.state.isDrawerOpen}
            onClose={this.toggleDrawer(false)}
            PaperProps={{ style: { width: "100%" } }}
          >
            <Box width={"100%"} onClick={this.toggleDrawer(false)}>
              <Box sx={{ padding: "48px 8% 0" }}>
                <Box
                  width={"100%"}
                  mb={"35px"}
                  display={"flex"}
                  justifyContent={"space-between"}  
                  alignItems={"center"}  
                  position={"relative"}           
                >
                  {this.renderUsernameForMobile()}
                  <Button
                    style={{ padding: "0", minWidth: "0" , position: "absolute" , right:"5%" }}
                    onClick={this.toggleDrawer(false)}
                  >
                    <Close style={{ width: "32px", height: "32px" }} />
                  </Button>
                </Box>
                <NavLink
                  data-test-id="homeNavLink2"
                  to="/"
                  exact
                  activeStyle={webStyles.activeNavLink}
                  style={webStyles.navLink}
                  onClick={this.handleNavigationScroll}
                >
                  <Typography style={{ padding: "24px 12px" }}>Home</Typography>
                </NavLink>
                <Divider/>
                {this.renderMobileDashboardButton()}
                <Divider />
                <NavLink
                  data-test-id="services2"
                  to="/Services"
                  activeStyle={webStyles.activeNavLink}
                  style={{ ...webStyles.navLink, width: "100%" }}
                  onClick={this.handleNavigationScroll}
                >
                  <Typography style={{ padding: "24px 12px" }}>
                    Services
                  </Typography>
                </NavLink>

                <Divider />
                <NavLink
                  data-test-id="aboutus2"
                  to="/Aboutus"
                  activeStyle={webStyles.activeNavLink}
                  style={{ ...webStyles.navLink, width: "100%" }}
                  onClick={this.handleNavigationScroll}
                >
                  <Typography style={{ padding: "24px 12px" }}>
                    About us
                  </Typography>
                </NavLink>

                <Divider />
                <NavLink
                  data-test-id="contactus2"
                  to="/Contactus"
                  activeStyle={webStyles.activeNavLink}
                  style={webStyles.navLink}
                  onClick={this.handleNavigationScroll}
                >
                  <Typography style={{ padding: "24px 12px" }}>
                    Contact us
                  </Typography>
                </NavLink>

                <Divider />
                <NavLink
                  data-test-id="faq2"
                  to="/Faq"
                  activeStyle={webStyles.activeNavLink}
                  style={webStyles.navLink}
                  onClick={this.handleNavigationScroll}
                >
                  <Typography style={{ padding: "24px 12px" }}>FAQs</Typography>
                </NavLink>
                {this.renderMobileLoginLogout()}
              </Box>
            </Box>
          </StyledDrawer>
        </MobileBox>

       
          

      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const DesktopBox = styled(Box)({
  "@media (max-width: 1231px)": {
    display: "none",
  },
  "& .headButton":{
    boxShadow: "none",
    border:"1px solid #ddd",
    borderRadius:"8px",
    "&:hover":{
      boxShadow: "none",
      backgroundColor:"transparent",
    }
  }
});

const MobileBox = styled(Box)({
  "@media (min-width: 1232px)": {
    display: "none",
  },
});

const StyledDrawer = styled(Drawer)({
  "@media (min-width: 1232px)": {
    display: "none",
  },
});

const webStyles = {
  headerbox: {
    position: "fixed",
    width: "100%",
    paddingTop: "32px",
    paddingBottom: "8px",
    zIndex: 999,
    backgroundColor: "white",
  },
  tabs: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    columnGap: "4px",
    color: "#000000",
    fontWeight: 500,
    fontSize: 16,
    fontFamily: "sans-serif",
    zIndex: 99,
  },
  loginsignup: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  login: {
    border: "1px solid #012275",
    fontWeight: 700,
    fontSize: 16,
    padding: "10px 33px",
    color: "#012275",
    marginRight: 12,
    fontFamily: "INTER",
    width: "125px",
    height: "48px",
  },
  signup: {
    backgroundColor: "#012275",
    color: "#FFFFFF",
    fontWeight: 700,
    fontSize: 16,
    padding: "12px 28px",
    fontFamily: "INTER",
    height: "48px",
    width: "125px",
  },
  boxes: {
    fontFamily: "INTER",
    cursor: "pointer",
    padding: "8px 16px",
  },
  navLink: {
    textDecoration: "none",
    fontFamily: "INTER",
    color: "#000000",
    cursor: "pointer",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 500,
  },
  activeNavLink: {
    color: "rgb(1,34,117)",
    fontWeight: 600,
  },
};
// Customizable Area End
