Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "termsandconditions";
exports.labelBodyText = "termsandconditions Body";

exports.btnExampleTitle = "CLICK ME";
exports.termsAndConditions= "bx_block_terms_and_conditions/terms_and_conditions";
exports.privacyPolicy= "bx_block_terms_and_conditions/privacy_policy";

// Customizable Area End